import React, { Fragment, useState, useRef, useEffect } from "react";
import _ from "lodash";
import FormInputFields from "./FormInputFields";
import postQuestionnairesFields from "../formData/postQuestionnairesFields";

export const PostQuestionnairesForm = (props) => {
  const inputRef = useRef({});

  const renderFields = () => {
    return _.map(
      postQuestionnairesFields,
      ({ label, name, type, options, required, disabled }) => {
        console.log(props.initailValues[name]);
        return (
          <FormInputFields
            name={name}
            label={label}
            type={type}
            value={inputRef.current.name}
            options={options}
            required={required}
            initailValue={props.initailValues[name]}
            onChange={props.onChange(name)}
            disabled={disabled}
          />
        );
      }
    );
  };
  useEffect(() => {
    inputRef.current = "";
  }, []);

  return <Fragment>{renderFields()}</Fragment>;
};
