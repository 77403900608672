import React, { Fragment, useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { Link, useParams, Redirect } from "react-router-dom";
import PageTitle from "../../layouts/PageTitle";
import { Button } from "react-bootstrap";

import { Row, Col, Card, Tabs, Tab, Nav } from "react-bootstrap";
import PatientDetail from "./PatientDetail";
import PatientNote from "./PatientNote";
import PatientForm from "./PatientForm";
import PatientCPAP from "./PatientCPAP";
import PatientActivity from "./PatientActivity";
import PatientReport from "./PatientReport";
import PatientSleepStudy from "./PatientSleepStudy";

import { Formik } from "formik";
import * as Yup from "yup";

import { db } from "../../../firebase";
import {
  setDoc,
  getDoc,
  getDocs,
  collection,
  updateDoc,
  doc,
} from "firebase/firestore";
import {
  PatientTextInputForm,
  PatientTextInputNotRequiredForm,
  PatientDropDownForm,
} from "./PatientUtils";
import { notifyTopRight, notifyWarning, notifyError } from "../toast/SetToast";
import Toast from "../toast/SetToast";
import { Route } from "react-router-dom/cjs/react-router-dom.min";
import { startSetCpaps } from "../../../actions/cpaps";
import useQueryParams from "../../../hook/useQueryParam";
import { useAuth } from "../../../context/AuthContext";

const loginSchema = Yup.object().shape({
  username: Yup.string()
    .min(3, "Your username must consist of at least 3 characters ")
    .max(50, "Your username must consist of at least 3 characters ")
    .required("Please enter a username"),
  password: Yup.string()
    .min(5, "Your password must be at least 5 characters long")
    .max(50, "Your password must be at least 5 characters long")
    .required("Please provide a password"),
});

const PatientPage = (props) => {
  const profileData = {
    hn: "",
    email: "",
    titleTh: "",
    firstnameTh: "",
    lastnameTh: "",
    nationalId: "",
    titleEn: "",
    firstnameEn: "",
    lastnameEn: "",
    dateOfBirth: "",
    status: "",
    ethnicity: "",
    nationality: "",
    religion: "",
    bloodType: "",
    note: "",
    gender: "",
    occupation: "",
    mobile: "",
    telephone: "",
    address1: "",
    address2: "",
    province: "",
    postcode: "",
    medicineIntolerance: "",
    healthInsurance: "",
    other: "",
    emergencyContact: "",
    relationship: "",
    emergencyContactPhone: "",
    nodeRef: "",
  };
  const { currentUser } = useAuth();

  const [showPassword, setShowPassword] = useState(false);
  const profileRef = useRef(profileData);
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();

  const { queryParams, setQueryParams } = useQueryParams({
    lastorder: null,
  });

  const marriedStatus = ["โสด", "สมรส", "หย่า", "แยกกันอยู่", "หม้าย", "สมณะ"];
  const genders = ["ชาย", "หญิง"];
  const paymentPrivilages = [
    "ชำระเงินเอง",
    "ประกันสุขภาพถ้วนหน้า",
    "ประกันสังคม",
    "กรมบัญชีกลาง",
    "กรุงเทพมหานคร",
    "ต้นสังกัด",
    "นักศึกษา มธ.",
    "โครงการวิจัยรับค่าบริการล่วงหน้า",
    "องค์การปกครองส่วนท้องถิ่น",
    "โครงการตรวจสุขภาพรับค่าบริการล่วงหน้า",
    "ผ่อนชำระ/นิติกร",
    "อุบัติเหตุและฉุกเฉินนอกโรงพยาบาล(EMS)",
    "ปลัดกระทรวงสาธารณะสุข",
    "โครงการ 85 ปี",
    "อื่นๆ",
  ];

  const submitHandler = async (e) => {
    try {
      e.preventDefault();
      const ref = doc(db, "patients", profileRef.current["hn"].value);
      const docSnap = await getDoc(ref);
      const updateData = {
        hn: profileRef.current["hn"].value,
        email: emailRef.current.value,
        titleTh: profileRef.current["titleTh"].value,
        firstnameTh: profileRef.current["firstnameTh"].value,
        lastnameTh: profileRef.current["lastnameTh"].value,
        nationalId: profileRef.current["nationalId"].value,
        titleEn: profileRef.current["titleEn"].value,
        firstnameEn: profileRef.current["firstnameEn"].value,
        lastnameEn: profileRef.current["lastnameEn"].value,
        dateOfBirth: profileRef.current["dateOfBirth"].value,
        status: profileRef.current["status"].value,
        ethnicity: profileRef.current["ethnicity"].value,
        nationality: profileRef.current["nationality"].value,
        religion: profileRef.current["religion"].value,
        bloodType: profileRef.current["bloodType"].value,
        note: profileRef.current["note"].value,
        gender: profileRef.current["gender"].value,
        occupation: profileRef.current["occupation"].value,
        mobile: profileRef.current["mobile"].value,
        telephone: profileRef.current["telephone"].value,
        address1: profileRef.current["address1"].value,
        address2: profileRef.current["address2"].value,
        province: profileRef.current["province"].value,
        postcode: profileRef.current["postcode"].value,
        medicineIntolerance: profileRef.current["medicineIntolerance"].value,
        healthInsurance: profileRef.current["healthInsurance"].value,
        other: profileRef.current["other"].value,
        emergencyContact: profileRef.current["emergencyContact"].value,
        relationship: profileRef.current["relationship"].value,
        emergencyContactPhone:
          profileRef.current["emergencyContactPhone"].value,
        nodeRef: profileRef.current["nodeRef"].value,
      };

      if (docSnap.exists()) {
        await updateDoc(ref, {
          updateData,
        });
      } else {
        await setDoc(ref, {
          updateData,
        });
      }

      if (passwordRef.current.value !== passwordConfirmRef.current.value) {
        notifyError("Password do not match");
        return;
      }
    } catch (err) {
      console.log(err);
      notifyError(`Error ${err}`);
    }
  };

  const tabData = [
    {
      name: "Profile",
      icon: "user",
      content: function (props) {
        return <PatientDetail profile={props} />;
      },
    },
    {
      name: "Form",
      icon: "clipboard",
      content: function (props) {
        return <PatientForm profile={props} />;
      },
    },
    {
      name: "Note",
      icon: "clipboard",
      content: function (props) {
        return <PatientNote profile={props} />;
      },
    },
    {
      name: "Activity",
      icon: "clipboard",
      content: function (props) {
        return <PatientActivity profile={props} />;
      },
    },
    {
      name: "Report",
      icon: "clipboard",
      content: function (props) {
        return <PatientReport profile={props} />;
      },
    },
  ];
  if (currentUser.roles[0] == "super_admin" || currentUser.roles[0] == "sale") {
    tabData.push(
      {
        name: "Sleep Study",
        icon: "clipboard",
        content: function (props) {
          return <PatientSleepStudy profile={props} />;
        },
      },

      {
        name: "CPAP",
        icon: "clipboard",
        content: function (props) {
          return <PatientCPAP profile={props} />;
        },
      }
    );
  }

  // console.log("last order", queryParams.lastorder);

  const { id } = useParams();
  const [patientInfo, setPatientInfo] = useState(profileData);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(async () => {
    // console.log(patientInfo);
    props.startSetCpaps();
    if (patientInfo.hn != "") {
      setIsLoading(false);
      return;
    }
    // console.log("id: ", id);
    const ref = doc(db, "patients", id);
    const docSnap = await getDoc(ref).catch((error) => console.log(error));

    setPatientInfo(docSnap.data());
  }, [patientInfo]);

  return (
    <>
      <PageTitle
        activeMenu={patientInfo.hn}
        motherMenu="Patient"
        motherLink="/patient-list"
      />

      {isLoading ? (
        <></>
      ) : (
        <Card>
          <Card.Header>
            <Card.Title className="col-xxl-auto pr-5">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {/* <div>{patientInfo.hn}</div> */}
                <div>
                  {patientInfo.firstnameTh} {patientInfo.lastnameTh}
                </div>
                <div>{queryParams.lastorder}</div>
              </div>{" "}
            </Card.Title>
          </Card.Header>
          <Card.Body>
            {/* <!-- Nav tabs --> */}
            <div className="default-tab">
              <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
                <Nav as="ul" className="nav-tabs">
                  {tabData.map((data, i) => (
                    <Nav.Item as="li" key={i}>
                      <Nav.Link eventKey={data.name.toLowerCase()}>
                        <i className={`la la-${data.icon} mr-2`} />
                        {data.name}
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
                <Tab.Content className="pt-4">
                  {tabData.map((data, i) => (
                    <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                      {data.content(patientInfo)}
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Tab.Container>
            </div>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  startSetCpaps: () => dispatch(startSetCpaps()),
});

// export default PatientPage;
export default connect(undefined, mapDispatchToProps)(PatientPage);
