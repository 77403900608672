import React, { useState, Component, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { Dropdown, Button, Col, Table, Card } from "react-bootstrap";
import Calendar from "react-calendar";

import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import PageTitle from "./../../layouts/PageTitle";

//import Images:
import avatar from "../../../images/avatar/1.jpg";
import avatar1 from "../../../images/avatar/2.jpg";
import avatar2 from "../../../images/avatar/3.jpg";
import { useAuth } from "../../../context/AuthContext";
import { db, storage } from "../../../firebase";
import {
  query,
  endAt,
  getDocs,
  collection,
  orderBy,
  limit,
  startAfter,
  endBefore,
  limitToLast,
  startAt,
  getCountFromServer,
  where,
  doc,
  deleteDoc,
  updateDoc,
  setDoc,
  collectionGroup,
  Timestamp,
  getDoc,
} from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  getBlob,
  deleteObject,
} from "firebase/storage";
import {
  notifyTopRight,
  notifyWarning,
  notifyError,
} from "../../components/toast/SetToast";
import Toast from "../../components/toast/SetToast";
// import { useAuth } from "../../../context/AuthContext";

const Dashboard = () => {
  const { currentUser } = useAuth();
  const [bookingMonthCount, setBookingMonthCount] = useState(0);
  const [cpapTrialMonthCount, setCPAPTrialMonthCount] = useState(0);
  const [cpapSaleMonthCount, setCPAPSaleMonthCount] = useState(0);
  const [cpapSaleMonthWaitingCount, setCPAPSaleMonthWaitingCount] = useState(0);
  const [bookingToday, setBookingToday] = useState([]);
  const [bookingTomorrow, setBookingTomorrow] = useState([]);
  const [cpapListToday, setCPAPListToday] = useState([]);
  const [bookingHFN, setBookingHFN] = useState(0);
  const [bookingFN, setBookingFN] = useState(0);
  const [bookingSN, setBookingSN] = useState(0);
  const [bookingTitrat, setBookingTitrat] = useState(0);
  const [availableCPAP, setAvailableCPAP] = useState(0);

  const FormatDate = (date) => {
    const [month, day, year] = date.split("/");
    return `${day}-${month}-${year}`;
  };

  const GetBookingToday = async () => {
    const TodayDateString = new Date().toISOString().slice(0, 10);
    const TodayDate = Timestamp.fromDate(
      new Date(TodayDateString + "T00:00:00")
    );
    const TomorrowDate = Timestamp.fromDate(
      new Date(TodayDateString + "T23:59:59")
    );
    // const statusFilter = ["Sleep Test Scheduled"]
    var bookingsCollectionGroupRef = collectionGroup(db, "bookings");
    var queryConstraints = [];
    queryConstraints.push(orderBy("start"));
    queryConstraints.push(orderBy("extendedProps.customer"));
    queryConstraints.push(orderBy("extendedProps.order"));
    queryConstraints.push(where("start", ">=", TodayDate));
    queryConstraints.push(where("start", "<=", TomorrowDate));
    queryConstraints.push(
      where("extendedProps.status", "==", "Sleep Test Scheduled")
    );
    if (currentUser.roles[0] == "sleep_technician") {
      queryConstraints.push(
        where("extendedProps.techrecord", "==", currentUser.displayName)
      );
    }

    let queryHandler = query(bookingsCollectionGroupRef, ...queryConstraints);
    const tempBooking = [];
    const docSnap = await getDocs(queryHandler);
    if (docSnap.empty) {
      console.log("No documents found");
    } else {
      docSnap.forEach((booking) => {
        const bookingData = booking.data();
        const dateStart = new Date(bookingData.start.toDate());
        dateStart.setHours(dateStart.getHours() + 7);
        bookingData.start = dateStart.toISOString().slice(0, 10);
        tempBooking.push({ ...bookingData });
      });
    }
    setBookingToday(tempBooking);
  };

  const GetBookingTomorrow = async () => {
    // const TodayDateString = new Date().toISOString().slice(0, 10);
    // const TodayDate = Timestamp.fromDate(
    //   new Date(TodayDateString + "T00:00:00")
    // );
    // const TomorrowDate = Timestamp.fromDate(
    //   new Date(TodayDateString + "T23:59:59")
    // );
    // const statusFilter = ["Sleep Test Scheduled"]
    // Get today's date
    const TodayDateString = new Date().toISOString().slice(0, 10);

    // Parse it as a Date object
    const TodayDate = new Date(TodayDateString);

    // Add one day to get tomorrow's date
    const TomorrowDateObject = new Date(TodayDate);
    TomorrowDateObject.setDate(TodayDate.getDate() + 1);

    // Convert tomorrow's date to Firestore Timestamp (midnight of tomorrow)
    const TomorrowDate = Timestamp.fromDate(
      new Date(TomorrowDateObject.toISOString().slice(0, 10) + "T00:00:00")
    );

    // Add one more day to TomorrowDateObject to get the end of tomorrow
    const TomorrowPlusOneDateObject = new Date(TomorrowDateObject);
    TomorrowPlusOneDateObject.setDate(TomorrowDateObject.getDate() + 1);

    // Convert to Firestore Timestamp (11:59:59 PM of tomorrow)
    const TomorrowPlusOneDate = Timestamp.fromDate(
      new Date(
        TomorrowPlusOneDateObject.toISOString().slice(0, 10) + "T23:59:59"
      )
    );

    var bookingsCollectionGroupRef = collectionGroup(db, "bookings");
    var queryConstraints = [];
    queryConstraints.push(orderBy("start"));
    queryConstraints.push(orderBy("extendedProps.customer"));
    queryConstraints.push(orderBy("extendedProps.order"));
    queryConstraints.push(where("start", ">=", TomorrowDate));
    queryConstraints.push(where("start", "<=", TomorrowPlusOneDate));
    queryConstraints.push(
      where("extendedProps.status", "==", "Sleep Test Scheduled")
    );
    if (currentUser.roles[0] == "sleep_technician") {
      queryConstraints.push(
        where("extendedProps.techrecord", "==", currentUser.displayName)
      );
    }

    let queryHandler = query(bookingsCollectionGroupRef, ...queryConstraints);
    const tempBooking = [];
    const docSnap = await getDocs(queryHandler);
    if (docSnap.empty) {
      console.log("No documents found");
    } else {
      docSnap.forEach((booking) => {
        const bookingData = booking.data();
        const dateStart = new Date(bookingData.start.toDate());
        dateStart.setHours(dateStart.getHours() + 7);
        bookingData.start = dateStart.toISOString().slice(0, 10);
        tempBooking.push({ ...bookingData });
      });
    }
    setBookingTomorrow(tempBooking);
  };

  const GetBookingThisMonth = async () => {
    const exceptStatus = ["Cancel", "Failure"];
    const today = new Date();

    // Set the time of the first day of the month to 00:00:00
    const firstDayOfMonth = new Date(
      today.getFullYear(),
      today.getMonth(),
      1,
      0,
      0,
      0
    );

    // Set the time of the last day of the month to 23:59:59
    const lastDayOfMonth = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0,
      23,
      59,
      59
    );

    // Convert these to Firestore-compatible Timestamps
    const firstDayString = firstDayOfMonth.toISOString(); // This will include the time as well
    const lastDayString = lastDayOfMonth.toISOString(); // This will include the time as well

    var bookingsCollectionGroupRef = collectionGroup(db, "bookings");
    var queryConstraints = [];
    queryConstraints.push(orderBy("extendedProps.latestSleepStudyDate"));
    queryConstraints.push(
      where("extendedProps.latestSleepStudyDate", ">=", firstDayString)
    );
    queryConstraints.push(
      where("extendedProps.latestSleepStudyDate", "<=", lastDayString)
    );
    queryConstraints.push(
      where("extendedProps.status", "not-in", exceptStatus)
    );
    if (currentUser.roles[0] == "sleep_technician") {
      queryConstraints.push(
        where("extendedProps.techrecord", "==", currentUser.displayName)
      );
    }
    let queryHandler = query(bookingsCollectionGroupRef, ...queryConstraints);
    const countData = await getCountFromServer(queryHandler);
    const count = countData.data().count;
    setBookingMonthCount(count);

    var queryType = [];
    queryType.push(orderBy("extendedProps.latestSleepStudyDate"));
    queryType.push(
      where("extendedProps.latestSleepStudyDate", ">=", firstDayString)
    );
    queryType.push(
      where("extendedProps.latestSleepStudyDate", "<=", lastDayString)
    );
    queryType.push(where("extendedProps.status", "not-in", exceptStatus));

    var queryTypeHFN = [...queryType];
    var queryTypeFN = [...queryType];
    var queryTypeSN = [...queryType];
    var queryTypeTitrat = [...queryType];

    queryTypeHFN.push(
      where("extendedProps.type", "==", "Home Full Night Sleep Study")
    );
    if (currentUser.roles[0] == "sleep_technician") {
      queryTypeHFN.push(
        where("extendedProps.techrecord", "==", currentUser.displayName)
      );
    }
    queryTypeFN.push(
      where("extendedProps.type", "==", "Full Night Sleep Study")
    );
    if (currentUser.roles[0] == "sleep_technician") {
      queryTypeFN.push(
        where("extendedProps.techrecord", "==", currentUser.displayName)
      );
    }
    queryTypeSN.push(
      where("extendedProps.type", "==", "Split Night Sleep Study")
    );
    if (currentUser.roles[0] == "sleep_technician") {
      queryTypeSN.push(
        where("extendedProps.techrecord", "==", currentUser.displayName)
      );
    }
    queryTypeTitrat.push(where("extendedProps.type", "==", "CPAP Titration"));
    if (currentUser.roles[0] == "sleep_technician") {
      queryTypeTitrat.push(
        where("extendedProps.techrecord", "==", currentUser.displayName)
      );
    }
    let queryHandlerHFN = query(bookingsCollectionGroupRef, ...queryTypeHFN);
    const countDataHFN = await getCountFromServer(queryHandlerHFN);
    const countHFN = countDataHFN.data().count;
    setBookingHFN(countHFN);

    let queryHandlerFN = query(bookingsCollectionGroupRef, ...queryTypeFN);
    const countDataFN = await getCountFromServer(queryHandlerFN);
    const countFN = countDataFN.data().count;
    setBookingFN(countFN);

    let queryHandlerSN = query(bookingsCollectionGroupRef, ...queryTypeSN);
    const countDataSN = await getCountFromServer(queryHandlerSN);
    const countSN = countDataSN.data().count;
    setBookingSN(countSN);

    let queryHandlerTitrat = query(
      bookingsCollectionGroupRef,
      ...queryTypeTitrat
    );
    const countDataTitrat = await getCountFromServer(queryHandlerTitrat);
    const countTitrat = countDataTitrat.data().count;
    setBookingTitrat(countTitrat);
  };

  const GetCPAPTrialThisMonth = async () => {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDayOfMonth = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0
    );

    const firstDayTimestamp = Timestamp.fromDate(
      new Date(firstDayOfMonth.toISOString().slice(0, 10) + "T00:00:00")
    );
    const lastDayTimestamp = Timestamp.fromDate(
      new Date(lastDayOfMonth.toISOString().slice(0, 10) + "T23:59:59")
    );

    var cpapCollectionRef = collection(db, "borrowings");
    var queryConstraints = [];
    queryConstraints.push(orderBy("BorrowDate"));
    queryConstraints.push(where("BorrowDate", ">=", firstDayTimestamp));
    queryConstraints.push(where("BorrowDate", "<=", lastDayTimestamp));
    queryConstraints.push(where("Activity", "==", "ยืมเครื่อง"));

    let queryHandler = query(cpapCollectionRef, ...queryConstraints);
    const querySnapshot = await getDocs(queryHandler);

    // Use a Set to filter out duplicates based on the HN field
    const uniqueHNs = new Set();
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      if (data.HN) {
        uniqueHNs.add(data.HN);
      }
    });

    const uniqueCount = uniqueHNs.size;
    setCPAPTrialMonthCount(uniqueCount);
  };

  const GetCPAPSaleThisMonth = async () => {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDayOfMonth = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0
    );

    const firstDayTimestamp = Timestamp.fromDate(
      new Date(firstDayOfMonth.toISOString().slice(0, 10) + "T00:00:00")
    );
    const lastDayTimestamp = Timestamp.fromDate(
      new Date(lastDayOfMonth.toISOString().slice(0, 10) + "T23:59:59")
    );
    var cpapCollectionRef = collection(db, "orders");
    var queryConstraints = [];
    queryConstraints.push(orderBy("receiptDate"));
    queryConstraints.push(where("receiptDate", ">=", firstDayTimestamp));
    queryConstraints.push(where("receiptDate", "<=", lastDayTimestamp));
    queryConstraints.push(where("activity", "==", "purchase"));
    queryConstraints.push(where("status", "==", "เสร็จสิ้น"));

    let queryHandler = query(cpapCollectionRef, ...queryConstraints);
    const countData = await getCountFromServer(queryHandler);
    const count = countData.data().count;
    setCPAPSaleMonthCount(count);

    var cpapCollectionWaitingRef = collection(db, "orders");
    var queryConstraintsWaiting = [];
    queryConstraintsWaiting.push(orderBy("receiptDate"));
    queryConstraintsWaiting.push(where("receiptDate", ">=", firstDayTimestamp));
    queryConstraintsWaiting.push(where("receiptDate", "<=", lastDayTimestamp));
    queryConstraintsWaiting.push(where("activity", "==", "purchase"));
    queryConstraintsWaiting.push(where("status", "==", "กำลังดำเนินการ"));

    let queryHandlerWaiting = query(
      cpapCollectionWaitingRef,
      ...queryConstraintsWaiting
    );
    const countDataWaiting = await getCountFromServer(queryHandlerWaiting);
    const countWaiting = countDataWaiting.data().count;
    setCPAPSaleMonthWaitingCount(countWaiting);
  };

  const GetCPAPBorrowingAvailable = async () => {
    const cpapCollectionGroupRef = collection(db, "cpaps");

    const query1 = query(cpapCollectionGroupRef, where("Status", "==", "ว่าง"));

    const querySnapshot = await getDocs(query1);

    // Get the length of the data
    const dataLength = querySnapshot.size;
    setAvailableCPAP(dataLength);
  };

  const GetCpapListToday = async () => {
    const TodayDateString = new Date().toISOString().slice(0, 10);
    const TodayDate = Timestamp.fromDate(
      new Date(TodayDateString + "T00:00:00")
    );
    const TomorrowDate = Timestamp.fromDate(
      new Date(TodayDateString + "T23:59:59")
    );
    const cpapCollectionGroupRef = collection(db, "cpaps");

    const query1 = query(
      cpapCollectionGroupRef,
      orderBy("LastDueDate"),
      where("Activity", "==", "ยืมเครื่อง"),
      // where("LastDueDate", "<=", TodayDate),
      where("LastDueDate", "<=", TomorrowDate)
    );
    // const querySnapshot = getDocs(query1)

    // Second query: LastDueDate is null
    const query2 = query(
      cpapCollectionGroupRef,
      orderBy("LastDueDate"),
      where("Activity", "==", "ยืมเครื่อง"),
      where("LastDueDate", "==", null)
    );

    // Execute both queries and combine results
    const [querySnapshot1, querySnapshot2] = await Promise.all([
      getDocs(query1),
      getDocs(query2),
    ]);

    const combinedResults = [];
    querySnapshot1.forEach((doc) => {
      const data = doc.data();
      // Convert Timestamp to Date or string for rendering
      if (data.LastDueDate) {
        data.LastDueDate = data.LastDueDate.toDate().toLocaleDateString();
      }
      combinedResults.push(data);
    });
    querySnapshot2.forEach((doc) => {
      const data = doc.data();
      // Convert Timestamp to Date or string for rendering
      if (data.LastDueDate) {
        data.LastDueDate = data.LastDueDate.toDate().toLocaleDateString();
      }
    });
    setCPAPListToday(combinedResults);
    // console.log(combinedResults);
  };
  const getThaiMonth = (monthNumber) => {
    const thaiMonths = [
      "มกราคม",
      "กุมภาพันธ์",
      "มีนาคม",
      "เมษายน",
      "พฤษภาคม",
      "มิถุนายน",
      "กรกฎาคม",
      "สิงหาคม",
      "กันยายน",
      "ตุลาคม",
      "พฤศจิกายน",
      "ธันวาคม",
    ];
    return thaiMonths[monthNumber];
  };

  const getThaiDate = (date) => {
    // Map for Thai days
    const days = [
      "วันอาทิตย์",
      "วันจันทร์",
      "วันอังคาร",
      "วันพุธ",
      "วันพฤหัสบดี",
      "วันศุกร์",
      "วันเสาร์",
    ];

    // Map for Thai months
    const months = [
      "มกราคม",
      "กุมภาพันธ์",
      "มีนาคม",
      "เมษายน",
      "พฤษภาคม",
      "มิถุนายน",
      "กรกฎาคม",
      "สิงหาคม",
      "กันยายน",
      "ตุลาคม",
      "พฤศจิกายน",
      "ธันวาคม",
    ];

    // Extract parts of the date
    const dayName = days[date.getDay()];
    const day = date.getDate();
    const monthName = months[date.getMonth()];
    const year = date.getFullYear() + 543; // Convert to Thai Buddhist Era (BE)

    // Format the date in Thai style
    return `${dayName} ที่ ${day} ${monthName} ${year}`;
  };

  const getTomorrowDate = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1); // Increment the date by 1
    return tomorrow;
  };

  useEffect(async () => {
    if (currentUser) {
      if (["super_admin", "sale"].includes(currentUser.roles[0])) {
        await GetBookingToday();
        await GetBookingThisMonth();
        await GetCPAPTrialThisMonth();
        await GetCPAPBorrowingAvailable();
        await GetCPAPSaleThisMonth();
        await GetCpapListToday();
      } else if (currentUser.roles[0] == "sleep_technician") {
        await GetBookingToday();
        await GetBookingTomorrow();
        await GetBookingThisMonth();
      }
      // console.log("Fetching data...")
    }
  }, []);
  return (
    <React.Fragment>
      <div className="mb-3">
        <h2 className="text-center">
          ภาพรวมประจำเดือน{" "}
          <strong>{getThaiMonth(new Date().getMonth())}</strong>
        </h2>
      </div>
      <div className="col-xl-12 col-xxl-12">
        <div className="row">
          <Link
            className={
              ["super_admin", "sale"].includes(currentUser.roles[0])
                ? "col-xl-3 col-lg-3 col-sm-3"
                : "col-xl-6 col-lg-6 col-sm-6"
            }
            to="/booking-calendar"
            // onClick={() => console.log("Kokkak")}
          >
            <div className="widget-stat card bg-primary pt-4">
              <Col className="media justify-content-center mb-3">
                <span>
                  <i className="flaticon-381-calendar-1 text-white"></i>
                </span>
              </Col>
              <Col>
                <h3 className="text-white text-center">{`จำนวนเคสทั้งหมด ${bookingMonthCount}`}</h3>
              </Col>
            </div>
          </Link>
          <Link
            className={
              ["super_admin", "sale"].includes(currentUser.roles[0])
                ? "col-xl-3 col-lg-3 col-sm-3"
                : "col-xl-6 col-lg-6 col-sm-6"
            }
            to="#"
            // onClick={() => console.log("Kokkak")}
          >
            <div className="widget-stat card bg-info pt-4 pb-4">
              {/* <Col className="media justify-content-center mb-3">
                <span>
                  <i className="flaticon-381-box-1 text-white"></i>
                </span>
              </Col> */}
              <Col>
                <h3 className="text-white text-center">
                  {`Full Night ${bookingFN}`}
                </h3>
              </Col>
              <Col>
                <h3 className="text-white text-center">
                  {`Split Night ${bookingSN}`}
                </h3>
              </Col>
              <Col>
                <h3 className="text-white text-center">
                  {`Home Full Night ${bookingHFN}`}
                </h3>
              </Col>
              <Col>
                <h3 className="text-white text-center">
                  {`CPAP Titration ${bookingTitrat}`}
                </h3>
              </Col>
            </div>
          </Link>
          {["super_admin", "sale"].includes(currentUser.roles[0]) && (
            <>
              {" "}
              <Link
                className="col-xl-3 col-lg-3 col-sm-3"
                // to="/trial-calendar"
                to={
                  currentUser.roles[0] === "super_admin"
                    ? "/trial-calendar"
                    : "#"
                }

                // onClick={() => console.log("Kokkak")}
              >
                <div className="widget-stat card bg-primary pt-4">
                  <Col className="media justify-content-center mb-3">
                    <span>
                      <i className="flaticon-381-box-2 text-white"></i>
                    </span>
                  </Col>
                  <Col>
                    <h3 className="text-white text-center">{`ยืม-เช่า เครื่องไปแล้ว ${cpapTrialMonthCount}`}</h3>
                  </Col>
                  <Col>
                    <h3 className="text-white text-center">{`เครื่องว่าง ${availableCPAP}`}</h3>
                  </Col>
                </div>
              </Link>
              <Link
                className="col-xl-3 col-lg-3 col-sm-3"
                to={
                  currentUser.roles[0] === "super_admin" ? "/order-list" : "#"
                }
                // onClick={() => console.log("Kokkak")}
              >
                <div className="widget-stat card bg-info pt-4">
                  <Col className="media justify-content-center mb-3">
                    <span>
                      <i className="flaticon-381-briefcase text-white"></i>
                    </span>
                  </Col>
                  <Col>
                    <h3 className="text-white text-center">{`ขายเครื่องสำเร็จ ${cpapSaleMonthCount}`}</h3>
                    <h3 className="text-white text-center">{`กำลังดำเนินการ ${cpapSaleMonthWaitingCount}`}</h3>
                  </Col>
                </div>
              </Link>
            </>
          )}
        </div>
      </div>
      <div className="col-xl-12 col-xxl-12">
        <div className="row">
          <Card className="col-xl-6 col-lg-6 col-sm-6">
            <div className="d-flex justify-content-between align-items-center mt-3 mb-3 ml-1">
              <h4 className="mb-0">
                <span className="text-primary">นัดตรวจวันนี้</span>
              </h4>
              <h4 className="mb-0">
                <span className="text-primary">{getThaiDate(new Date())}</span>
              </h4>
            </div>
            <Table className="table-responsive-12 mb-0 table-striped">
              <thead>
                <tr className="bg-primary text-white">
                  <th>ลำดับ</th>
                  {/* <th>Customer</th> */}
                  <th>Order</th>
                  <th>ชื่อ - นามสกุล</th>
                  <th>เบอร์ติดต่อ</th>
                  <th>Technician</th>
                </tr>
              </thead>
              <tbody>
                {bookingToday.map((booking, index) => {
                  return (
                    <tr>
                      <td>
                        <h4>{index + 1}</h4>
                      </td>
                      {/* <td>{booking.extendedProps.customer}</td> */}
                      <td>{booking.extendedProps.order}</td>
                      <td>
                        <a href={`/patient-page/${booking.extendedProps.hn}`}>
                          {booking.extendedProps.firstname}{" "}
                          {booking.extendedProps.lastname}
                        </a>
                      </td>
                      <td>{booking.extendedProps.phone}</td>
                      <td>{booking.extendedProps.techrecord}</td>
                    </tr>
                  );
                })}
                {bookingToday.length < 10 &&
                  [...Array(10 - bookingToday.length)].map((booking, index) => {
                    return (
                      <tr>
                        <td>
                          <h4>{index + bookingToday.length + 1}</h4>
                        </td>
                        <td>{""}</td>
                        <td>{""}</td>
                        <td>{""}</td>
                        <td>{""}</td>
                        <td>{""}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Card>
          {["super_admin", "sale"].includes(currentUser.roles[0]) ? (
            <>
              {" "}
              <Card className="col-xl-6 col-lg-6 col-sm-6">
                <h4 className="mt-3 mb-3 ml-1">
                  <text className="text-primary">เลยกำหนดคืนเครื่อง</text>
                </h4>
                <Table className="table-responsive-12 mb-0 table-striped">
                  <thead>
                    <tr className="bg-primary text-white">
                      <th>ลำดับ</th>
                      <th>หมายเลขเครื่อง</th>
                      <th>ชื่อ - นามสกุล</th>
                      <th>เบอร์ติดต่อ</th>
                      <th>วันที่นัดคืน</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cpapListToday.map((cpap, index) => {
                      return (
                        <tr>
                          <td>
                            <h4>{index + 1}</h4>
                          </td>
                          <td>
                            <a href={`/cpap-page/${cpap.SerialNumber}`}>
                              {cpap.SerialNumber}
                            </a>
                          </td>
                          <td>
                            <a href={`/patient-page/${cpap.HN}`}>
                              {cpap.LastFirstName} {cpap.LastLastName}
                            </a>
                          </td>
                          <td>{cpap.LastTel}</td>
                          <td>{FormatDate(cpap.LastDueDate)}</td>
                        </tr>
                      );
                    })}
                    {cpapListToday.length < 10 &&
                      [...Array(10 - cpapListToday.length)].map(
                        (cpap, index) => {
                          return (
                            <tr>
                              <td>
                                <h4>{index + cpapListToday.length + 1}</h4>
                              </td>
                              <td>{""}</td>
                              <td>{""}</td>
                              <td>{""}</td>
                              <td>{""}</td>
                            </tr>
                          );
                        }
                      )}
                  </tbody>
                </Table>
              </Card>
            </>
          ) : (
            <>
              {" "}
              <Card className="col-xl-6 col-lg-6 col-sm-6">
                <div className="d-flex justify-content-between align-items-center mt-3 mb-3 ml-1">
                  <h4 className="mb-0">
                    <span className="text-primary">นัดตรวจพรุ่งนี้วันนี้</span>
                  </h4>
                  <h4 className="mb-0">
                    <span className="text-primary">
                      {getThaiDate(getTomorrowDate())}
                    </span>
                  </h4>
                </div>

                <Table className="table-responsive-12 mb-0 table-striped">
                  <thead>
                    <tr className="bg-primary text-white">
                      <th>ลำดับ</th>
                      {/* <th>Customer</th> */}
                      <th>Order</th>
                      <th>ชื่อ - นามสกุล</th>
                      <th>เบอร์ติดต่อ</th>
                      <th>Technician</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bookingTomorrow.map((booking, index) => {
                      return (
                        <tr>
                          <td>
                            <h4>{index + 1}</h4>
                          </td>
                          {/* <td>{booking.extendedProps.customer}</td> */}
                          <td>{booking.extendedProps.order}</td>
                          <td>
                            <a
                              href={`/patient-page/${booking.extendedProps.hn}`}
                            >
                              {booking.extendedProps.firstname}{" "}
                              {booking.extendedProps.lastname}
                            </a>
                          </td>
                          <td>{booking.extendedProps.phone}</td>
                          <td>{booking.extendedProps.techrecord}</td>
                        </tr>
                      );
                    })}
                    {bookingTomorrow.length < 10 &&
                      [...Array(10 - bookingTomorrow.length)].map(
                        (booking, index) => {
                          return (
                            <tr>
                              <td>
                                <h4>{index + bookingTomorrow.length + 1}</h4>
                              </td>
                              <td>{""}</td>
                              <td>{""}</td>
                              <td>{""}</td>
                              <td>{""}</td>
                              <td>{""}</td>
                            </tr>
                          );
                        }
                      )}
                  </tbody>
                </Table>
              </Card>
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
