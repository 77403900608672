import React, { Fragment, useState, useRef, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Row, Col, Card, Tabs, Tab, Nav } from "react-bootstrap";

import { Formik } from "formik";
import * as Yup from "yup";
// import { useAuth, currentUser } from "../../../context/AuthContext";
import { db } from "../../../../firebase";
import {
  setDoc,
  getDoc,
  getDocs,
  collection,
  updateDoc,
  doc,
} from "firebase/firestore";
import {
  PatientTextInputForm,
  PatientTextInputNotRequiredForm,
  PatientDropDownForm,
} from "../PatientUtils";
import {
  notifyTopRight,
  notifyWarning,
  notifyError,
} from "../../../components/toast/SetToast";
import Toast from "../../../components/toast/SetToast";
import { Route } from "react-router-dom/cjs/react-router-dom.min";
import profileData from "../../Patient/formData/profileData";
import { InputBox, InputArea, DropdownBox } from "../formData/inputComponents";
import postQuestionnairesData from "../formData/postQuestionnairesData";
import { PostQuestionnairesForm } from "./PostQuestionnairesForm";

const PostQuestionnaires = () => {
  const history = useHistory();
  const [patientInfo, setPatientInfo] = useState(profileData);
  const postQuestionnairesRef = useRef(postQuestionnairesData);
  const [postQuestionnairesDoc, setPostQuestionnairesDoc] = useState(
    postQuestionnairesData
  );

  const [isLoading, setLoading] = useState(true);
  const { id, uuid } = useParams();

  const inputRef = useRef({
    firstName: "",
    lastName: "",
    sleepStudyDate: "",
    sleepStudyTime: "",
    currentFeeling: "",
    timeToFallAsleepEstimated: "",
    actualTimeToFallAsleep: "",
    numberOfWakings: "",
    timeAwake1: "",
    timeAwake2: "",
    timeAwake3: "",
    timeAwake4: "",
    timeAwake5: "",
    timeAwake6: "",
    timeInBed: "",
    timeAsleep: "",
    timeAwakeInBed: "",
    sleepQuality: "",
    deepSleepLevel: "",
    currentFeelingDetail: "",
    sleepDisruption: "",
    sleepDisruptionNote: "",
    wakeupReason: "",
    physicalDiscomfort: "",
    physicalDiscomfortNote: "",
    currentMoodStatement: "",
  });

  const preSleepInfoData = {
    assign: false,
    formname: "แบบสอบถามหลังการตรวจการนอนหลับ",
    id: "2",
    info: inputRef.current,
    submit: false,
  };

  const handleChange = (name) => (e) => {
    inputRef.current[name] = e.target.value;
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    let info = {
      firstName: inputRef.current.firstName,
      lastName: inputRef.current.lastName,
      sleepStudyDate: inputRef.current.sleepStudyDate,
      sleepStudyTime: inputRef.current.sleepStudyTime,
      currentFeeling: inputRef.current.currentFeeling,
      timeToFallAsleepEstimated: inputRef.current.timeToFallAsleepEstimated,
      actualTimeToFallAsleep: inputRef.current.actualTimeToFallAsleep,
      numberOfWakings: inputRef.current.numberOfWakings,
      timeAwake1: inputRef.current.timeAwake1,
      timeAwake2: inputRef.current.timeAwake2,
      timeAwake3: inputRef.current.timeAwake3,
      timeAwake4: inputRef.current.timeAwake4,
      timeAwake5: inputRef.current.timeAwake5,
      timeAwake6: inputRef.current.timeAwake6,
      timeInBed: inputRef.current.timeInBed,
      timeAsleep: inputRef.current.timeAsleep,
      timeAwakeInBed: inputRef.current.timeAwakeInBed,
      sleepQuality: inputRef.current.sleepQuality,
      deepSleepLevel: inputRef.current.deepSleepLevel,
      currentFeelingDetail: inputRef.current.currentFeelingDetail,
      sleepDisruption: inputRef.current.sleepDisruption,
      sleepDisruptionNote: inputRef.current.physicalDiscomfortNote,
      wakeupReason: inputRef.current.wakeupReason,
      physicalDiscomfort: inputRef.current.physicalDiscomfort,
      physicalDiscomfortNote: inputRef.current.physicalDiscomfortNote,
      currentMoodStatement: inputRef.current.currentMoodStatement,
    };

    const ref = doc(db, "patients", patientInfo.hn, "form", uuid);
    const docSnap = await getDoc(ref);
    if (docSnap.exists()) {
      try {
        var tmpDocSnap = docSnap.data();
        tmpDocSnap.info = info;
        tmpDocSnap.submit = true;
        var dateNow = new Date();
        dateNow.setHours(dateNow.getHours() + 7);
        tmpDocSnap.updateAt = dateNow.toISOString();
        console.log(tmpDocSnap);
        await updateDoc(ref, tmpDocSnap);
        notifyTopRight("บันทึกข้อมูลสำเร็จ");
      } catch (err) {
        notifyError("บันทึกข้อมูลผิดพลาด, ", err);
      }
    }
  };

  useEffect(async () => {
    if (patientInfo.hn != undefined && patientInfo.hn != "") {
      let docColRef = doc(db, "patients", id, "form", uuid);
      let docSnapForm = await getDoc(docColRef);
      setPostQuestionnairesDoc(docSnapForm.data().info);
      setLoading(false);
      postQuestionnairesRef.current["firstName"] =
        docSnapForm.data().info.firstName;
      postQuestionnairesRef.current["lastName"] =
        docSnapForm.data().info.lastName;
      postQuestionnairesRef.current["sleepStudyDate"] =
        docSnapForm.data().info.sleepStudyDate;
      postQuestionnairesRef.current["sleepStudyTime"] =
        docSnapForm.data().info.sleepStudyTime;
      postQuestionnairesRef.current["currentFeeling"] =
        docSnapForm.data().info.currentFeeling;
      postQuestionnairesRef.current["timeToFallAsleepEstimated"] =
        docSnapForm.data().info.timeToFallAsleepEstimated;
      postQuestionnairesRef.current["actualTimeToFallAsleep"] =
        docSnapForm.data().info.actualTimeToFallAsleep;
      postQuestionnairesRef.current["numberOfWakings"] =
        docSnapForm.data().info.numberOfWakings;

      postQuestionnairesRef.current["timeAwake1"] =
        docSnapForm.data().info.timeAwake1;
      postQuestionnairesRef.current["timeAwake2"] =
        docSnapForm.data().info.timeAwake2;
      postQuestionnairesRef.current["timeAwake3"] =
        docSnapForm.data().info.timeAwake3;
      postQuestionnairesRef.current["timeAwake4"] =
        docSnapForm.data().info.timeAwake4;
      postQuestionnairesRef.current["timeAwake5"] =
        docSnapForm.data().info.timeAwake5;
      postQuestionnairesRef.current["timeAwake6"] =
        docSnapForm.data().info.timeAwake6;

      postQuestionnairesRef.current["timeInBed"] =
        docSnapForm.data().info.timeInBed;
      postQuestionnairesRef.current["timeAsleep"] =
        docSnapForm.data().info.timeAsleep;
      postQuestionnairesRef.current["timeAwakeInBed"] =
        docSnapForm.data().info.timeAwakeInBed;
      postQuestionnairesRef.current["sleepQuality"] =
        docSnapForm.data().info.sleepQuality;
      postQuestionnairesRef.current["deepSleepLevel"] =
        docSnapForm.data().info.deepSleepLevel;
      postQuestionnairesRef.current["currentFeelingDetail"] =
        docSnapForm.data().info.currentFeelingDetail;
      postQuestionnairesRef.current["sleepDisruption"] =
        docSnapForm.data().info.sleepDisruption;
      postQuestionnairesRef.current["sleepDisruptionNote"] =
        docSnapForm.data().info.sleepDisruptionNote;
      postQuestionnairesRef.current["wakeupReason"] =
        docSnapForm.data().info.wakeupReason;
      postQuestionnairesRef.current["physicalDiscomfort"] =
        docSnapForm.data().info.physicalDiscomfort;
      postQuestionnairesRef.current["physicalDiscomfortNote"] =
        docSnapForm.data().info.physicalDiscomfortNote;
      postQuestionnairesRef.current["currentMoodStatement"] =
        docSnapForm.data().info.currentMoodStatement;
      return;
    }

    const ref = doc(db, "patients", id);
    const docSnap = await getDoc(ref);
    setPatientInfo(docSnap.data());
  }, [patientInfo]);

  return (
    <Fragment>
      <Toast />
      <Col xl={12}>
        <Card>
          <Card.Header>
            <Card.Title>แบบสอบถามหลังตรวจการนอนหลับ</Card.Title>
          </Card.Header>
          <Card.Body>
            <div className="basic-form">
              <form onSubmit={submitHandler}>
                <div className="row">
                  <div className="col-xxl-12">
                    {!isLoading && (
                      <PostQuestionnairesForm
                        onChange={handleChange}
                        initailValues={postQuestionnairesDoc}
                      />
                    )}
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-10">
                    <button type="submit" className="btn btn-primary">
                      บันทึกข้อมูล
                    </button>
                    <button
                      onClick={() => {
                        history.push(`/patient-page/${id}`);
                      }}
                      type="submit"
                      className="btn btn-light ml-2"
                    >
                      ยกเลิก
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Fragment>
  );
};

export default PostQuestionnaires;
