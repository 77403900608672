import React, { Fragment, useState, useRef, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import Toast from "../../toast/SetToast";
import {
  notifyTopRight,
  notifyWarning,
  notifyError,
} from "../../toast/SetToast";
import { useFirestore } from "../../../../hook/useFirestore";

import { AccountantEditForm } from "./AccEditForm";

const AccountantEdit = (props) => {
  const { updateDocument } = useFirestore("accountants");

  const inputRef = useRef({
    accountantId: "",
    name: "",
    node: "",
    fullNameTh: "",
    email: "",
    status: "",
  });

  const handleChange = (name) => (e) => {
    inputRef.current[name] = e.target.value;
  };
  const handleOnHideModal = () => {
    props.hide();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      accountantId: inputRef.current.accountantId
        ? inputRef.current.accountantId
        : props.item.accountantId,
      name: inputRef.current.name ? inputRef.current.name : props.item.name,
      node: inputRef.current.node ? inputRef.current.node : props.item.node,
      fullNameTh: inputRef.current.fullNameTh
        ? inputRef.current.fullNameTh
        : props.item.fullNameTh,
      email: inputRef.current.email ? inputRef.current.email : props.item.email,
      status: inputRef.current.status
        ? inputRef.current.status
        : props.item.status,
    };
    updateDocument(props.item.id, formData)
      .then(() => {
        notifyTopRight("บันทึกข้อมูลสำเร็จ");
      })
      .catch((err) => {
        notifyError("บันทึกข้อมูลผิดพลาด ", err);
      });

    props.hide();

    inputRef.current.accountantId = "";
    inputRef.current.name = "";
    inputRef.current.node = "";
    inputRef.current.fullNameTh = "";
    inputRef.current.email = "";
    inputRef.current.status = "";
  };

  return (
    <Fragment>
      <Toast />
      <Modal
        className="fade bd-example-modal-lg"
        show={props.show}
        onHide={handleOnHideModal}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>Edit Accountant Datails</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AccountantEditForm
            onChange={handleChange}
            initailValues={props.item}
          />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
        <div className="d-flex justify-content-center mb-3">
          <Button className="sm" onClick={handleSubmit}>
            Save
          </Button>

          <Button
            className="sm ml-2"
            variant="light"
            onClick={handleOnHideModal}
          >
            Cancel
          </Button>
        </div>
      </Modal>
    </Fragment>
  );
};

export default AccountantEdit;
