export default [
  {
    label: "Node",
    name: "node",
    type: "text",
    required: true,
  },
  {
    label: "Accountant ID",
    name: "accountantId",
    type: "text",
    required: true,
  },
  {
    label: "Name",
    name: "name",
    type: "text",
    required: true,
  },
  {
    label: "Full Name Thai",
    name: "fullNameTh",
    type: "text",
  },
  {
    label: "Email",
    name: "email",
    type: "text",
  },
  {
    label: "Status",
    name: "status",
    type: "select",
    options: ["Available", "Unavailable"],
  },
];
