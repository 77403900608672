import { db, storage } from "../firebase";
import {
  setDoc,
  getDocs,
  collection,
  updateDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { ref, uploadBytes, deleteObject, listAll } from "firebase/storage";

// ADD file
export const addFile = (file) => ({
  type: "ADD_FILE",
  file,
});

export const startAddFile = (fileData = {}, files, type = "cpapfile") => {
  // console.log("start add note", noteData, files);
  return async (dispatch) => {
    const {
      title = "",
      productId = "",
      createBy = "",
      createAt = 0,
    } = fileData;
    const file = { title, productId, createBy, createAt };

    const docRef = doc(collection(db, "products", file.productId, type));
    await setDoc(docRef, file)
      .then(() => {
        // console.log("set doc", docRef);
        const promises = [];
        const f = [];
        files.map((item) => {
          const storageRef = ref(
            storage,
            `${type}s/${file.productId}/${docRef.id}/${item.name}`
          );
          f.push(item.name);
          const uploadTask = uploadBytes(storageRef, item);
          promises.push(uploadTask);
        });
        Promise.all(promises);
        // .then(() => console.log("All images uploaded"))
        // .catch((err) => console.log(err));
        updateDoc(doc(db, "products", file.productId, type, docRef.id), {
          cpapFile: f,
        });
        dispatch(
          addFile({
            id: docRef.id,
            ...file,
            cpapFile: f,
          })
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

// REMOVE File
export const removeFile = ({ id } = {}) => ({
  type: "REMOVE_FILE",
  id,
});

export const startRemoveFile = ({ productId, id, type = "cpapfile" }) => {
  return async (dispatch) => {
    const docRef = doc(db, "products", productId, type, id);
    return await deleteDoc(docRef).then(() => {
      const storageRef = ref(storage, `${type}s/${productId}/${id}`);
      listAll(storageRef).then((res) => {
        res.items.forEach((itemRef) => {
          deleteObject(itemRef);
        });
      });

      dispatch(removeFile({ id }));
    });
  };
};

// SET Report
export const setFiles = (files) => ({
  type: "SET_FILE",
  files,
});
export const startSetFiles = (productId, type = "cpapfile") => {
  return async (dispatch) => {
    console.log("type: ", type);
    const docRef = collection(db, "products", productId, type);
    return await getDocs(docRef).then((snapshot) => {
      const files = [];
      snapshot.forEach(async (childSnapshot) => {
        // console.log(childSnapshot);
        files.push({
          id: childSnapshot.id,
          ...childSnapshot.data(),
        });
      });

      dispatch(setFiles(files));
    });
  };
};
