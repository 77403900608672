export default [
  {
    label: "ชื่อ",
    name: "firstName",
    type: "text",
    required: true,
  },
  {
    label: "นามสกุล",
    name: "lastName",
    type: "text",
    required: true,
  },
  {
    label: "วันที่ตรวจ",
    name: "sleepStudyDate",
    type: "date",
  },
  {
    label: "เวลา",
    name: "sleepStudyTime",
    type: "time",
  },
  {
    label: "1. ตอนนี้ ท่านรู้สึกอย่างไรบ้าง (ให้คะแนนเป็น 5 เมื่อยังง่วงนิด ๆ)",
    name: "currentFeeling",
    type: "select",
    options: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
  },
  {
    label: "2. เมื่อคืน ท่านคิดว่า ท่านใช้เวลานานเท่าไหร่กว่าจะหลับ",
    name: "timeToFallAsleepEstimated",
    type: "text",
  },
  {
    label: "3. เมื่อคืน ท่านใช้เวลากว่าจะหลับ",
    name: "actualTimeToFallAsleep",
    type: "select",
    options: ["นานกว่าปกติ", "สั้นกว่าปกติ", "เหมือนปกติ"],
  },
  {
    label: "4. เมื่อคืน ท่านรู้สึกว่า ตื่นกี่ครั้ง",
    name: "numberOfWakings",
    type: "text",
  },
  {
    label: "ครั้งที่ 1",
    name: "timeAwake1",
    type: "text",
  },
  {
    label: "ครั้งที่ 2",
    name: "timeAwake2",
    type: "text",
  },
  {
    label: "ครั้งที่ 3",
    name: "timeAwake3",
    type: "text",
  },
  {
    label: "ครั้งที่ 4",
    name: "timeAwake4",
    type: "text",
  },
  {
    label: "ครั้งที่ 5",
    name: "timeAwake5",
    type: "text",
  },
  {
    label: "ครั้งที่ 6",
    name: "timeAwake6",
    type: "text",
  },

  {
    label: "5. ท่านคิดว่า เมื่อคืนนี้อยู่ที่เตียงนานเท่าไร",
    name: "timeInBed",
    type: "text",
  },
  {
    label: "6. ท่านคิดว่า ท่านหลับจริงเท่าไร",
    name: "timeAsleep",
    type: "text",
  },
  {
    label: "7. ท่านคิดว่า ท่านรู้สึกตัวตื่นอยู่ขณะนอนอยู่บนเตียงนานเท่าไร",
    name: "timeAwakeInBed",
    type: "text",
  },
  {
    label: "8. ท่านรู้สึกว่า การนอนเมื่อคืนเป็นอย่างไรบ้าง",
    name: "sleepQuality",
    type: "select",
    options: ["ดีมาก", "ดี", "เหมือนปกติ", "แย่", "แย่มาก"],
  },
  {
    label: "9. เมื่อคืนนี้ท่านหลับสนิทแค่ไหน",
    name: "deepSleepLevel",
    type: "select",
    options: [
      "สนิทดีมาก",
      "ดี",
      "ไม่สนิทนัก/ถูกกวนบ้าง",
      "ไม่สนิทเลย/ถูกกวนมาก",
    ],
  },
  {
    label: "10. ตอนนี้ท่านรู้สึกอย่างไรบ้าง",
    name: "currentFeelingDetail",
    type: "select",
    options: ["สดชื่น", "เพลีย", "เพลียมาก"],
  },
  {
    label: "11. มีอะไรรบกวนการนอนของท่าน เมื่อคืนหรือไม่",
    name: "sleepDisruption",
    type: "select",
    options: ["ไม่มี", "มี"],
  },

  {
    label: "ถ้ามี โปรดระบุ...",
    name: "sleepDisruptionNote",
    type: "text",
  },
  {
    label: "12. อะไรที่ทำให้ท่านตื่นนอนวันนี้",
    name: "wakeupReason",
    type: "select",
    options: [
      "ท่านตื่นเอง",
      "เสียงรบกวน",
      "รู้สึกไม่สบายตัว",
      "เจ้าหน้าที่เข้ามา (กรณีมีเจ้าหน้าที่เฝ้า)",
    ],
  },
  {
    label: "13. ตอนนี้ ท่านรู้สึกผิดปกติที่ส่วนใดของร่างกายหรือไม่",
    name: "physicalDiscomfort",
    type: "select",
    options: ["ไม่มี", "มี"],
  },
  {
    label: "ถ้ามี โปรดระบุ...",
    name: "physicalDiscomfortNote",
    type: "text",
  },
  {
    label:
      "14. เลือกข้อความด้านล่างนี้ที่ตรงกับความรู้สึกของท่าน ขณะนี้มากที่สุด",
    name: "currentMoodStatement",
    type: "select",
    options: [
      "ตื่นดีมาก ไม่รู้สึกง่วง หากนอนต่อก็นอนไม่หลับ",
      "ตื่นดี ไม่รู้สึกง่วง หากนอนต่อ คิดว่าอาจจะหลับต่อได้",
      "ตื่นดี ไม่รู้สึกง่วง แต่ถ้าจะให้นอนก็หลับต่อได้",
      "ยังรู้สึกง่วงนอนอยู่ ให้นอนก็หลับต่อได้",
      "ง่วงนอน อยากไปนอนต่อ",
      "อยากนอนต่อมาก ๆ ไม่สามารถลืมตาได้",
    ],
  },
];
