import moment from "moment";

// Get visible cpap

export default (cpaps, { text }) => {
  return cpaps
    .filter((cpap) => {
      const textMatch = cpap.serialNumber
        .toLowerCase()
        .includes(text.toLowerCase());
      return textMatch;
    })
    .sort((a, b) => {
      return a.status < b.status ? 1 : -1;
    });
};
