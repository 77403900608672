import { db, storage } from "../firebase";
import {
  setDoc,
  getDocs,
  collection,
  updateDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { ref, uploadBytes, deleteObject, listAll } from "firebase/storage";

// ADD NOTE
export const addNote = (note) => ({
  type: "ADD_NOTE",
  note,
});

export const startAddNote = (noteData = {}, files) => {
  // console.log("start add note", noteData, files);
  return async (dispatch) => {
    const {
      title = "",
      detail = "",
      filename = [],
      hn = "",
      createBy = "",
      updateAt = 0,
      createAt = 0,
    } = noteData;
    const note = { title, detail, filename, hn, createBy, updateAt, createAt };
    // console.log("action note", note);

    const docRef = doc(collection(db, "patients", note.hn, "note"));
    await setDoc(docRef, note)
      .then(() => {
        // console.log("set doc", docRef);
        const promises = [];
        const f = [];
        files.map((file) => {
          const storageRef = ref(
            storage,
            `files/${note.hn}/${docRef.id}/${file.name}`
          );
          f.push(file.name);
          const uploadTask = uploadBytes(storageRef, file);
          promises.push(uploadTask);
        });
        Promise.all(promises);
        // .then(() => console.log("All images uploaded"))
        // .catch((err) => console.log(err));
        updateDoc(doc(db, "patients", note.hn, "note", docRef.id), {
          filename: f,
        });
        dispatch(
          addNote({
            id: docRef.id,
            ...note,
            filename: f,
          })
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

// REMOVE NOTE
export const removeNote = ({ id } = {}) => ({
  type: "REMOVE_NOTE",
  id,
});

export const startRemoveNote = ({ hn, id }) => {
  return async (dispatch) => {
    const docRef = doc(db, "patients", hn, "note", id);
    return await deleteDoc(docRef).then(() => {
      const storageRef = ref(storage, `files/${hn}/${id}`);
      listAll(storageRef).then((res) => {
        res.items.forEach((itemRef) => {
          deleteObject(itemRef);
        });
      });

      dispatch(removeNote({ id }));
    });
  };
};

// EDIT NOTE
export const editNote = (id, updates) => ({
  type: "EDIT_NOTE",
  id,
  updates,
});

export const startEditNote = (id, updates, files) => {
  return async (dispatch) => {
    const docRef = doc(db, "patients", updates.hn, "note", id);

    const f = [];

    //delete file
    const storageRef = ref(storage, `files/${updates.hn}/${id}`);
    listAll(storageRef).then((res) => {
      const removeRef = res.items.filter(
        (itemRef) => !updates.filename.includes(itemRef.name)
      );
      removeRef.forEach((res) => {
        const storageRef = ref(
          storage,
          `files/${updates.hn}/${id}/${res.name}`
        );
        deleteObject(storageRef, res.name);
      });
    });
    //upload file
    if (files != null) {
      const promises = [];
      files.map((file) => {
        const storageRef = ref(
          storage,
          `files/${updates.hn}/${docRef.id}/${file.name}`
        );
        f.push(...updates.filename, file.name);
        const uploadTask = uploadBytes(storageRef, file);
        promises.push(uploadTask);
      });
      Promise.all(promises);
    }

    // console.log("startEditNote", updates);
    // console.log("startEditNote file", files);
    // console.log("f", f);

    return await updateDoc(docRef, {
      ...updates,
      filename: f.length === 0 ? updates.filename : f,
    }).then(() => {
      dispatch(
        editNote(id, {
          ...updates,
          filename: f.length === 0 ? updates.filename : f,
        })
      );
    });
  };
};

// SET NOTE
export const setNotes = (notes) => ({
  type: "SET_NOTE",
  notes,
});
export const startSetNotes = (hn) => {
  return async (dispatch) => {
    const docRef = collection(db, "patients", hn, "note");
    return await getDocs(docRef).then((snapshot) => {
      const notes = [];
      snapshot.forEach(async (childSnapshot) => {
        notes.push({
          id: childSnapshot.id,
          ...childSnapshot.data(),
        });
      });

      dispatch(setNotes(notes));
    });
  };
};
