const postQuestionnairesData = {
  firstName: "",
  lastName: "",
  sleepStudyDate: "",
  sleepStudyTime: "",
  currentFeeling: "",
  timeToFallAsleepEstimated: "",
  actualTimeToFallAsleep: "",
  numberOfWakings: "",
  timeAwake1: "",
  timeAwake2: "",
  timeAwake3: "",
  timeAwake4: "",
  timeAwake5: "",
  timeAwake6: "",
  timeInBed: "",
  timeAsleep: "",
  timeAwakeInBed: "",
  sleepQuality: "",
  deepSleepLevel: "",
  currentFeelingDetail: "",
  sleepDisruption: "",
  sleepDisruptionNote: "",
  wakeupReason: "",
  physicalDiscomfort: "",
  physicalDiscomfortNote: "",
  currentMoodStatement: "",
};

export default postQuestionnairesData;
