import React, { Fragment, useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import PageTitle from "../../layouts/PageTitle";

import { useAuth } from "../../../context/AuthContext";
import { db } from "../../../firebase";
import {
  query,
  where,
  getDoc,
  updateDoc,
  doc,
  collection,
  getDocs,
  setDoc,
} from "firebase/firestore";

import { notifyTopRight, notifyWarning, notifyError } from "../toast/SetToast";
import Toast from "../toast/SetToast";
import { startAddOrder } from "../../../actions/orders";
import { startEditCpap } from "../../../actions/cpaps";
import selectCpaps from "../../../selectors/cpaps";
import { OrderForm } from "./OrderForm";
import { startAddNote } from "../../../actions/notes";
import { OrderEditForm } from "./OrderEditForm";

const ProductOrderRegister = (props) => {
  const { currentUser } = useAuth();
  const [isPurchase, setIsPurchase] = useState(false);
  const [saleOrderNumber, setSaleOrderNumber] = useState();
  const [patient, setPatient] = useState();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileNames, setFilenames] = useState([]);
  const { id, sn, dn } = useParams();
  const inputRef = useRef({
    // receiptNo: "",
    hn: "",
    amount: "",
    salePackage: "",
    mask: "",
    maskSize: "",
    receiptDate: "",
    refHospital: "",
    refPhysician: "",
    note: "",
    status: null,
    activity: null,
    warrantyStartDate: null,
    warrantyEndDate: null,
    sales: null,
  });

  async function getNextSequence() {
    const sequenceRef = doc(db, "sequenceTracker", "saleOrderSequence");

    try {
      const sequenceDoc = await getDoc(sequenceRef);

      if (!sequenceDoc.exists()) {
        throw new Error("Sequence document does not exist!");
      }

      const data = sequenceDoc.data();
      const currentDate = new Date();
      const currentMonth = (currentDate.getMonth() + 1)
        .toString()
        .padStart(2, "0");
      const currentYear = currentDate.getFullYear();

      // Check if the month has changed and reset if necessary
      if (data.month !== currentMonth || data.year !== currentYear) {
        await setDoc(sequenceRef, {
          current: 1,
          month: currentMonth,
          year: currentYear,
        });
        return "001";
      }

      // Get the current sequence
      const current = data.current;
      return current.toString().padStart(3, "0");
    } catch (e) {
      console.error("Failed to get sequence: ", e);
      throw e;
    }
  }

  async function updateSequence(newSequence) {
    const sequenceRef = doc(db, "sequenceTracker", "saleOrderSequence");

    try {
      await updateDoc(sequenceRef, { current: newSequence });
    } catch (e) {
      console.error("Failed to update sequence: ", e);
      throw e;
    }
  }

  const generateId = async () => {
    const currentDate = new Date();
    const thaiYear = currentDate.getFullYear() + 543;
    const year = thaiYear.toString().slice(-2);
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");

    const sequence = await getNextSequence();

    return `IGC${year}${month}${sequence}`;
  };

  const handleChange = (name) => (e) => {
    if (name === "nationalImage") {
      for (let i = 0; i < e.target.files.length; i++) {
        const f = e.target.files[i];
        setSelectedFiles((prevState) => [...prevState, f]);
        setFilenames((prevState) => [...prevState, f.name]);
        console.log(f.name);
      }
    } else {
      inputRef.current[name] = e.target.value;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const ref = doc(db, "patients", inputRef.current.hn);
    const docSnap = await getDoc(ref);
    if (docSnap.exists()) {
      //check if purchase order already created
      const orderQuery = query(
        collection(db, "orders"),
        where("productSn", "==", sn),
        where("activity", "==", "purchase")
      );
      const orderDoc = await getDocs(orderQuery);
      if (!orderDoc.empty) {
        if (inputRef.current.activity === "purchase") {
          notifyError(
            "Purchase order already created, Re-create please delete the old purchase order"
          );
          return;
        }
      }

      const formData = {
        // receiptNo: inputRef.current.receiptNo,
        receiptNo: saleOrderNumber,
        hn: inputRef.current.hn,
        amount: inputRef.current.amount,

        salePackage: inputRef.current.salePackage,
        mask: inputRef.current.mask,
        maskSize: inputRef.current.maskSize,

        receiptDate: inputRef.current.receiptDate
          ? new Date(inputRef.current.receiptDate)
          : null,
        // refHospital: inputRef.current.refHospital,
        refHospital: docSnap.data().refHn,
        refPhysician: inputRef.current.refPhysician,
        sales: inputRef.current.sales,
        note: inputRef.current.note,
        productId: id,
        productSn: sn,
        deviceNo: dn,
        status: inputRef.current.status,
        activity: inputRef.current.activity,
        createdBy: currentUser.displayName,
        warrantyStartDate: inputRef.current.warrantyStartDate
          ? new Date(inputRef.current.warrantyStartDate)
          : null,
        warrantyEndDate: inputRef.current.warrantyEndDate
          ? new Date(inputRef.current.warrantyEndDate)
          : null,
        createdAt: new Date(),
        updatedAt: new Date(),
        firstname: docSnap.data().firstnameTh,
        lastname: docSnap.data().lastnameTh,
        nodeRef:
          docSnap.data().nodeRef === undefined ? "" : docSnap.data().nodeRef,
        refCustomer:
          docSnap.data().refCustomer === undefined
            ? ""
            : docSnap.data().refCustomer,
        mobile: docSnap.data().mobile,
        address: docSnap.data().address1,
      };

      if (fileNames.length > 0) {
        props.startAddNote(
          {
            title: "National ID Card",
            detail: "",
            filename: fileNames,
            hn: inputRef.current.hn,
            createBy: currentUser.displayName,
            updateAt: new Date(),
            createAt: new Date(),
          },
          selectedFiles
        );
      }

      props
        .startAddOrder(formData)
        .then(() => notifyTopRight("บันทึกข้อมูลสำเร็จ"))
        .catch((err) => {
          notifyError("บันทึกข้อมูลผิดพลาด ", err);
        });
      props.startEditCpap(id, { status: "ขาย" });

      const currentSequence = await getNextSequence();
      const newSequence = parseInt(currentSequence, 10) + 1;
      await updateSequence(newSequence);
    } else {
      notifyError("Invalid HN !");
    }
  };

  useEffect(() => {
    // Generate the sale order number when the component mounts
    generateId().then((id) => setSaleOrderNumber(id));
  }, []);

  return (
    <Fragment>
      <Toast />
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Order Form</h4>
            </div>
            <div className="card-body">
              <div className="form-validation">
                <form
                  className="form-valide"
                  action="#"
                  method="post"
                  onSubmit={handleSubmit}
                >
                  <div className="row">
                    <div className="col-xl-6">
                      <OrderForm
                        onChange={handleChange}
                        saleOrderNumber={saleOrderNumber}
                        fileNames={fileNames}
                      />
                      <br /> <br />
                      <div className="form-group row">
                        <div className="col-lg-8 ml-auto">
                          <button type="submit" className="btn btn-primary">
                            Submit
                          </button>
                          <Link
                            to="/product-list"
                            type="submit"
                            className="btn btn-light ml-2"
                          >
                            Cancel
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state, props) => ({
  cpap: selectCpaps(state.cpaps, { text: props.match.params.sn }),
});

const mapDispatchToProps = (dispatch) => ({
  startAddOrder: (order) => dispatch(startAddOrder(order)),
  startEditCpap: (id, updates) => dispatch(startEditCpap(id, updates)),
  startAddNote: (note, files) => dispatch(startAddNote(note, files)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductOrderRegister);
