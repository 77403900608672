import React, {
  Fragment,
  useState,
  useRef,
  useEffect,
  useLayoutEffect,
} from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Row, Col, Card, Tabs, Tab, Nav } from "react-bootstrap";

import { Formik } from "formik";
import * as Yup from "yup";
// import { useAuth, currentUser } from "../../../context/AuthContext";
import { db } from "../../../../firebase";
import {
  setDoc,
  getDoc,
  getDocs,
  collection,
  updateDoc,
  doc,
} from "firebase/firestore";
import {
  PatientTextInputForm,
  PatientTextInputNotRequiredForm,
  PatientDropDownForm,
} from "../PatientUtils";
import {
  notifyTopRight,
  notifyWarning,
  notifyError,
} from "../../../components/toast/SetToast";
import Toast from "../../../components/toast/SetToast";

const infoData = {
  //Personal Information
  firstName: "",
  lastName: "",
  gender: "",
  dateOfBirth: "",
  age: "",
  weight: "",
  height: "",

  educationLevel: "",
  occupation: "",
  workScheduleType: "",
  workHoursPerDay: "",
  workHoursPerWeek: "",

  //Medical Information
  chronicDiseases: "",
  pastSurgeries: "",
  pastSurgeriesNote: "",
  currentMedications: "",
  currentMedicationsNote: "",
  sleepingPills: "",
  sleepingPillsFrequency: "",

  smokingStatus: "",
  alcoholConsumption: "",
  caffeineConsumption: "",
  weightChangeLastYear: "",
  weightChangeNote: "",
  exerciseStatus: "",
  familySleepDisorders: "",
  familySleepDisordersNote: "",

  sleepingPosition: "",

  symptoms: "",
  symptomsNote: "",
  symptomsDuration: "",

  //Epworth Sleepiness Scala
  readingSleepiness: "",
  tvWatchingSleepiness: "",
  publicPlaceSleepiness: "",
  longTripPassengerSleepiness: "",
  afterLunchSleepiness: "",
  conversationSleepiness: "",
  afternoonRestSleepiness: "",
  trafficStopSleepiness: "",

  //SleepSchedule
  weekdayBedtime: "",
  weekdayWakeupTime: "",
  weekdayFallAsleepTime: "",
  weekdayAverageSleepHours: "",
  weekdayNightAwakenings: "",
  weekdayNightAwakeningsCount: "",
  weekdayNightAwakeDuration: "",

  weekendBedtime: "",
  weekendWakeupTime: "",
  weekendFallAsleepTime: "",
  weekendAverageSleepHours: "",
  weekendNightAwakenings: "",
  weekendNightAwakeningsCount: "",
  weekendNightAwakeDuration: "",

  napStatus: "",
  napCount: "",
  napTime: "",

  worksShifts: "",
  shiftStartTime: "",
  shiftEndTime: "",
  shiftDaysPerWeek: "",
  shiftDays: "",
  shiftDayBedtime: "",
  shiftDayWakeupTime: "",

  nonShiftDayBedtime: "",
  nonShiftDayWakeupTime: "",

  //SymptomAnalysis
  difficultyFallingAsleep: "",
  legCrampsAtNight: "",
  wakesUpAtNight: "",
  nightWakeReason: "",
  nightWakeFrequency: "",
  nightWakeTime: "",
  difficultyReturningToSleep: "",

  nightSweats: "",
  legCrampsOrCrawlingSensation: "",
  confusionBetweenRealityAndDream: "",
  snoring: "",
  snoringLoudness: "",
  breathingPausesDuringSleep: "",
  chokingSensationDuringSleep: "",
  fistClenchingDuringSleep: "",
  limbJerkingDuringSleep: "",
  restlessnessAndSweatingDuringSleep: "",
  sleepwalkingOrTalking: "",

  feelingAfterWakingUp: "",
  overallSleepQuality: "",
};

const profileData = {
  username: "",
  email: "",
  titleTh: "",
  firstnameTh: "",
  lastnameTh: "",
  titleEn: "",
  firstnameEn: "",
  lastnameEn: "",
  dateOfBirth: "",
  status: "",
  ethnicity: "",
  nationality: "",
  religion: "",
  bloodType: "",
  note: "",
  gender: "",
  occupation: "",
  mobile: "",
  telephone: "",
  address1: "",
  address2: "",
  province: "",
  postcode: "",
  medicineIntolerance: "",
  healthInsurance: "",
  other: "",
  emergencyContact: "",
  relationship: "",
  emergencyContactPhone: "",
};
const demographicData = {
  assign: false,
  formname: "ข้อมูลพื้นฐาน",
  id: "1",
  info: infoData,
  submit: false,
};
const Demographic = () => {
  // const profileRef = useRef(profileData);
  const demographicRef = useRef(infoData);
  const history = useHistory();
  const [patientInfo, setPatientInfo] = useState(profileData);
  const [demographicDoc, setDemographicDoc] = useState(demographicData);
  const [isLoading, setLoading] = useState(true);
  const { id, uuid } = useParams();
  // console.log("uuid: ", uuid);
  const DropdownBox = ({
    title = "",
    htmlFor = "",
    id = "",
    name = "",
    refvalue,
    placeholder = "Please select",
    options = [""],
    defaultValue,
  }) => {
    return (
      <div className="form-group row">
        <label className="col-xl-4 col-form-label" htmlFor={htmlFor}>
          {title}
          <span className="text-danger"></span>
        </label>
        <div className="col-xl-4">
          <select
            className="form-control"
            id={id}
            name={name}
            ref={refvalue}
            defaultValue={defaultValue}
          >
            {options.map((value) => (
              <option value={value}>{value}</option>
            ))}
          </select>
        </div>
      </div>
    );
  };
  const InputBox = ({
    title = "",
    value = "",
    placeholder = "",
    id = "",
    measure = "",
    refValue,
    noBox = false,
    defaultValue,
    required,
    type = "text",
  }) => {
    return (
      <div className="form-group row">
        <label className="col-xl-4 col-form-label mt-2">{title}</label>
        <div className="col-xl-4">
          {noBox == false && (
            <input
              type={type}
              className="form-control"
              placeholder={placeholder == "" ? title : placeholder}
              id={id}
              ref={refValue}
              defaultValue={defaultValue}
              required={required}
            />
          )}
        </div>
        <div className="ml-3 mt-3">
          <text>{measure}</text>
        </div>
      </div>
    );
  };
  const InputRadio = ({
    title = "",
    id = "",
    options = [""],
    checked = "",
    name = "",
    isnote = false,
    note = "",
    mt = "row mt-3",
    refValue,
    refNote,
    required,
  }) => {
    return (
      <fieldset className="form-group">
        <div className={mt}>
          <label className="col-xl-4 col-form-label pt-0">{title}</label>
          <div className="col-xl-3">
            {options.map((value, idx) => {
              return (
                <>
                  <div className="form-check" key={idx}>
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="radio"
                        name={name}
                        value={value}
                        required={required}
                        // id={id}
                        onChange={(e) => {
                          demographicRef.current[refValue] = e.target.value;
                        }}
                        defaultChecked={checked == value ? "checked" : ""}
                      />
                      {value}
                    </label>
                  </div>
                </>
              );
            })}
          </div>
          {isnote && (
            <div className="form-check">
              {" "}
              <textarea
                className="form-control"
                id="val-suggestions"
                name="val-suggestions"
                rows="2"
                placeholder=""
                ref={(e) => {
                  demographicRef.current[refNote] = e;
                }}
                defaultValue={note}
              ></textarea>
            </div>
          )}
        </div>
      </fieldset>
    );
  };
  const InputCheckBox = ({
    title = "",
    id = "",
    options = [""],
    checked = [""],
    name = "",
    refValue,
    mt = "row mt-3",
  }) => {
    return (
      <fieldset className="form-group">
        <div className={mt}>
          <label className="col-xl-4 col-form-label pt-0">{title}</label>
          <div className="col-xl-2">
            {options.map((value) => {
              return (
                <>
                  <div className="form-check">
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name={name}
                        value={value}
                        onChange={(e) => {
                          // demographicRef.current[refValue] = e.target.value;
                          console.log(e.target.value);
                        }}
                        defaultChecked={
                          checked.includes(value) ? "checked" : ""
                        }
                      />
                      {value}
                    </label>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </fieldset>
    );
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    var info = {
      //Personal Information
      firstName: demographicRef.current["gender"].value,
      lastName: demographicRef.current["gender"].value,
      gender: demographicRef.current["gender"].value,
      dateOfBirth: demographicRef.current["dateOfBirth"].value,
      age: demographicRef.current["age"].value,
      weight: demographicRef.current["weight"].value,
      height: demographicRef.current["height"].value,

      educationLevel: demographicRef.current["educationLevel"],
      occupation: demographicRef.current["occupation"].value,
      workScheduleType: demographicRef.current["workScheduleType"],
      workHoursPerDay: demographicRef.current["workHoursPerDay"].value,
      workHoursPerWeek: demographicRef.current["workHoursPerWeek"].value,
      //Medical Information
      chronicDiseases: demographicRef.current["chronicDiseases"].value,
      pastSurgeries: demographicRef.current["pastSurgeries"],
      pastSurgeriesNote: demographicRef.current["pastSurgeriesNote"].value,
      currentMedications: demographicRef.current["currentMedications"],
      currentMedicationsNote:
        demographicRef.current["currentMedicationsNote"].value,
      sleepingPills: demographicRef.current["sleepingPills"],
      sleepingPillsFrequency:
        demographicRef.current["sleepingPillsFrequency"].value,

      smokingStatus: demographicRef.current["smokingStatus"],
      alcoholConsumption: demographicRef.current["alcoholConsumption"],
      caffeineConsumption: demographicRef.current["caffeineConsumption"],
      weightChangeLastYear: demographicRef.current["weightChangeLastYear"],
      weightChangeNote: demographicRef.current["weightChangeNote"].value,
      exerciseStatus: demographicRef.current["exerciseStatus"],
      familySleepDisorders: demographicRef.current["familySleepDisorders"],
      familySleepDisordersNote:
        demographicRef.current["familySleepDisordersNote"].value,

      sleepingPosition: demographicRef.current["sleepingPosition"],

      symptoms: demographicRef.current["symptoms"],
      symptomsNote: demographicRef.current["symptomsNote"].value,
      symptomsDuration: demographicRef.current["symptomsDuration"].value,

      //Epworth Sleepiness Scala
      readingSleepiness: demographicRef.current["readingSleepiness"],
      tvWatchingSleepiness: demographicRef.current["tvWatchingSleepiness"],
      publicPlaceSleepiness: demographicRef.current["publicPlaceSleepiness"],
      longTripPassengerSleepiness:
        demographicRef.current["longTripPassengerSleepiness"],
      afterLunchSleepiness: demographicRef.current["afterLunchSleepiness"],
      conversationSleepiness: demographicRef.current["conversationSleepiness"],
      afternoonRestSleepiness:
        demographicRef.current["afternoonRestSleepiness"],
      trafficStopSleepiness: demographicRef.current["trafficStopSleepiness"],

      //SleepSchedule
      weekdayBedtime: demographicRef.current["weekdayBedtime"].value,
      weekdayWakeupTime: demographicRef.current["weekdayWakeupTime"].value,
      weekdayFallAsleepTime:
        demographicRef.current["weekdayFallAsleepTime"].value,
      weekdayAverageSleepHours:
        demographicRef.current["weekdayAverageSleepHours"].value,
      weekdayNightAwakenings: demographicRef.current["weekdayNightAwakenings"],
      weekdayNightAwakeningsCount:
        demographicRef.current["weekdayNightAwakeningsCount"].value,
      weekdayNightAwakeDuration:
        demographicRef.current["weekdayNightAwakeDuration"].value,

      weekendBedtime: demographicRef.current["weekendBedtime"].value,
      weekendWakeupTime: demographicRef.current["weekendWakeupTime"].value,
      weekendFallAsleepTime:
        demographicRef.current["weekendFallAsleepTime"].value,
      weekendAverageSleepHours:
        demographicRef.current["weekendAverageSleepHours"].value,
      weekendNightAwakenings: demographicRef.current["weekendNightAwakenings"],
      weekendNightAwakeningsCount:
        demographicRef.current["weekendNightAwakeningsCount"].value,
      weekendNightAwakeDuration:
        demographicRef.current["weekendNightAwakeDuration"].value,

      napStatus: demographicRef.current["napStatus"],
      napCount: demographicRef.current["napCount"].value,
      napTime: demographicRef.current["napTime"].value,

      worksShifts: demographicRef.current["worksShifts"],
      shiftStartTime: demographicRef.current["shiftStartTime"].value,
      shiftEndTime: demographicRef.current["shiftEndTime"].value,
      shiftDaysPerWeek: demographicRef.current["shiftDaysPerWeek"].value,
      shiftDays: demographicRef.current["shiftDays"].value,
      shiftDayBedtime: demographicRef.current["shiftDayBedtime"].value,
      shiftDayWakeupTime: demographicRef.current["shiftDayWakeupTime"].value,

      nonShiftDayBedtime: demographicRef.current["nonShiftDayBedtime"].value,
      nonShiftDayWakeupTime:
        demographicRef.current["nonShiftDayWakeupTime"].value,

      //SymptomAnalysis
      difficultyFallingAsleep:
        demographicRef.current["difficultyFallingAsleep"],
      legCrampsAtNight: demographicRef.current["legCrampsAtNight"],
      wakesUpAtNight: demographicRef.current["wakesUpAtNight"],
      nightWakeReason: demographicRef.current["nightWakeReason"].value,
      nightWakeFrequency: demographicRef.current["nightWakeFrequency"].value,
      nightWakeTime: demographicRef.current["nightWakeTime"].value,
      difficultyReturningToSleep:
        demographicRef.current["difficultyReturningToSleep"],

      nightSweats: demographicRef.current["nightSweats"],
      legCrampsOrCrawlingSensation:
        demographicRef.current["legCrampsOrCrawlingSensation"],
      confusionBetweenRealityAndDream:
        demographicRef.current["confusionBetweenRealityAndDream"],
      snoring: demographicRef.current["snoring"],
      snoringLoudness: demographicRef.current["snoringLoudness"],
      breathingPausesDuringSleep:
        demographicRef.current["breathingPausesDuringSleep"],
      chokingSensationDuringSleep:
        demographicRef.current["chokingSensationDuringSleep"],
      fistClenchingDuringSleep:
        demographicRef.current["fistClenchingDuringSleep"],
      limbJerkingDuringSleep: demographicRef.current["limbJerkingDuringSleep"],
      restlessnessAndSweatingDuringSleep:
        demographicRef.current["restlessnessAndSweatingDuringSleep"],
      sleepwalkingOrTalking: demographicRef.current["sleepwalkingOrTalking"],

      feelingAfterWakingUp: demographicRef.current["feelingAfterWakingUp"],
      overallSleepQuality: demographicRef.current["overallSleepQuality"],
    };
    // console.log("info: ", info);
    // console.log("hn: ", patientInfo.hn);
    // console.log("uuid: ", uuid);
    const ref = doc(db, "patients", patientInfo.hn, "form", uuid);
    const docSnap = await getDoc(ref);
    if (docSnap.exists()) {
      try {
        var tmpDocSnap = docSnap.data();
        tmpDocSnap.info = info;
        tmpDocSnap.submit = true;
        var dateNow = new Date();
        dateNow.setHours(dateNow.getHours() + 7);
        tmpDocSnap.updateAt = dateNow.toISOString();
        // console.log(tmpDocSnap);
        await updateDoc(ref, tmpDocSnap);
        notifyTopRight("บันทึกข้อมูลสำเร็จ");
      } catch (err) {
        console.log(err);
        notifyError("บันทึกข้อมูลผิดพลาด, ", err);
      }
    }
  };
  useEffect(async () => {
    if (patientInfo.hn != undefined && patientInfo.hn != "") {
      // console.log('patientInfo.hn: ',patientInfo.hn)
      let docColRef = doc(db, "patients", id, "form", uuid);
      let docSnapForm = await getDoc(docColRef);
      // console.log(docSnapForm.data());
      setDemographicDoc(docSnapForm.data());

      // demographicRef.current["workScheduleType"] =
      //   docSnapForm.data().info.workScheduleType;
      // demographicRef.current["heartattack"] =
      //   docSnapForm.data().info.heartattack;
      // demographicRef.current["highBloodPressure"] =
      //   docSnapForm.data().info.highBloodPressure;
      // demographicRef.current["bloodClots"] = docSnapForm.data().info.bloodClots;
      // demographicRef.current["diabetes"] = docSnapForm.data().info.diabetes;
      // demographicRef.current["hyperlipidemia"] =
      //   docSnapForm.data().info.hyperlipidemia;
      // demographicRef.current["kidneyDisease"] =
      //   docSnapForm.data().info.kidneyDisease;
      // demographicRef.current["fattyLiver"] = docSnapForm.data().info.fattyLiver;
      // demographicRef.current["overactiveThyroid"] =
      //   docSnapForm.data().info.overactiveThyroid;
      // demographicRef.current["underactiveThtroid"] =
      //   docSnapForm.data().info.underactiveThtroid;
      // demographicRef.current["pale"] = docSnapForm.data().info.pale;
      // demographicRef.current["ironDeficiency"] =
      //   docSnapForm.data().info.ironDeficiency;
      // demographicRef.current["thalassemia"] =
      //   docSnapForm.data().info.thalassemia;
      // demographicRef.current["asthma"] = docSnapForm.data().info.asthma;
      // demographicRef.current["emphysema"] = docSnapForm.data().info.emphysema;
      // demographicRef.current["pulmonaryHypertension"] =
      //   docSnapForm.data().info.pulmonaryHypertension;
      // demographicRef.current["parkinson"] = docSnapForm.data().info.parkinson;
      // demographicRef.current["muscleWeakness"] =
      //   docSnapForm.data().info.muscleWeakness;
      // demographicRef.current["depressed"] = docSnapForm.data().info.depressed;
      // demographicRef.current["other"] = docSnapForm.data().info.other;
      // demographicRef.current["regularMedicine"] =
      //   docSnapForm.data().info.regularMedicine;

      // demographicRef.current["smoke"] = docSnapForm.data().info.smoke;

      // demographicRef.current["alc"] = docSnapForm.data().info.alc;

      // demographicRef.current["coffee"] = docSnapForm.data().info.coffee;

      // demographicRef.current["soda"] = docSnapForm.data().info.soda;

      // demographicRef.current["apnea"] = docSnapForm.data().info.apnea;
      // demographicRef.current["apneaDetail"] =
      //   docSnapForm.data().info.apneaDetail;
      // demographicRef.current["snore"] = docSnapForm.data().info.snore;
      // demographicRef.current["snoreDetail"] =
      //   docSnapForm.data().info.snoreDetail;
      // demographicRef.current["unusuallySleepy"] =
      //   docSnapForm.data().info.unusuallySleepy;
      // demographicRef.current["unusuallySleepyDetail"] =
      //   docSnapForm.data().info.unusuallySleepyDetail;
      // demographicRef.current["insomnia"] = docSnapForm.data().info.insomnia;
      // demographicRef.current["insomniaDetail"] =
      //   docSnapForm.data().info.insomniaDetail;

      setLoading(false);

      return;
    }

    const ref = doc(db, "patients", id);
    const docSnap = await getDoc(ref);
    setPatientInfo(docSnap.data());
  }, [patientInfo]);
  // console.log(demographicDoc);

  return (
    <Fragment>
      <Toast />

      <Col xl={12}>
        <Card>
          <Card.Header>
            <Card.Title>ข้อมูลพื้นฐาน (Demographic)</Card.Title>
          </Card.Header>
          <Card.Body>
            {!isLoading ? (
              <div className="basic-form">
                <form onSubmit={submitHandler}>
                  <div className="row">
                    <div className="col-xl-10">
                      <h4 className="mb-4">
                        <b>ข้อมูลส่วนตัว</b>
                      </h4>
                      <InputBox
                        title="ชื่อ"
                        measure=""
                        refValue={(el) =>
                          (demographicRef.current["firstName"] = el)
                        }
                        defaultValue={demographicDoc.info.firstName}
                        required={true}
                      ></InputBox>
                      <InputBox
                        title="นามสกุล"
                        measure=""
                        refValue={(el) =>
                          (demographicRef.current["lastName"] = el)
                        }
                        defaultValue={demographicDoc.info.lastName}
                        required={true}
                      ></InputBox>
                      <DropdownBox
                        title="เพศ"
                        htmlFor="gender"
                        id="เพศ"
                        name="เพศ"
                        placeholder="กดเพื่อเลือก"
                        options={["ชาย", "หญิง"]}
                        refvalue={(el) =>
                          (demographicRef.current["gender"] = el)
                        }
                        defaultValue={demographicDoc.info.gender}
                      />
                      <InputBox
                        title="วันเกิด"
                        required={true}
                        refValue={(el) =>
                          (demographicRef.current["dateOfBirth"] = el)
                        }
                        defaultValue={demographicDoc.info.dateOfBirth}
                        type="date"
                      ></InputBox>
                      <InputBox
                        title="อายุ"
                        measure="ปี"
                        refValue={(el) => (demographicRef.current["age"] = el)}
                        defaultValue={demographicDoc.info.age}
                        required={true}
                      ></InputBox>
                      <InputBox
                        title="น้ำหนัก"
                        measure="กิโลกรัม"
                        required={true}
                        refValue={(el) =>
                          (demographicRef.current["weight"] = el)
                        }
                        defaultValue={demographicDoc.info.weight}
                      ></InputBox>
                      <InputBox
                        title="ส่วนสูง"
                        measure="เซนติเมตร"
                        required={true}
                        refValue={(el) =>
                          (demographicRef.current["height"] = el)
                        }
                        defaultValue={demographicDoc.info.height}
                      ></InputBox>
                      <InputRadio
                        title="ระดับการศึกษา "
                        required={true}
                        options={[
                          "ประถมศึกษา",
                          "มัธยมศึกษา",
                          "อนุปริญญา",
                          "ปริญญาตรี",
                          "สูงกว่าปริญญาตรี",
                        ]}
                        name="ระดับการศึกษา "
                        checked={demographicDoc.info.educationLevel}
                        refValue={"educationLevel"}
                      ></InputRadio>
                      <InputBox
                        title="อาชีพ"
                        measure=""
                        refValue={(el) =>
                          (demographicRef.current["occupation"] = el)
                        }
                        defaultValue={demographicDoc.info.occupation}
                      ></InputBox>
                      <InputRadio
                        title="ลักษณะการทำงาน"
                        options={["กลางวัน", "กลางคืน", "ทำงานเป็นกะ"]}
                        name="ลักษณะการทำงาน"
                        checked={demographicDoc.info.workScheduleType}
                        refValue={"workScheduleType"}
                      ></InputRadio>
                      <InputBox
                        title="ระยะเวลาในการทำงาน"
                        measure="ชั่วโมงต่อวัน"
                        refValue={(el) =>
                          (demographicRef.current["workHoursPerDay"] = el)
                        }
                        defaultValue={demographicDoc.info.workHoursPerDay}
                      ></InputBox>
                      <InputBox
                        title="ระยะเวลาในการทำงาน"
                        measure="ชั่วโมงต่อสัปดาห์"
                        refValue={(el) =>
                          (demographicRef.current["workHoursPerWeek"] = el)
                        }
                        defaultValue={demographicDoc.info.workHoursPerWeek}
                      ></InputBox>
                      <InputBox
                        title="โรคประจำตัว (ระบุ)"
                        measure=""
                        refValue={(el) =>
                          (demographicRef.current["chronicDiseases"] = el)
                        }
                        defaultValue={demographicDoc.info.chronicDiseases}
                      ></InputBox>
                      <InputRadio
                        title="ท่านเคยผ่าตัดบริเวณใบหน้า หู คอ จมูก"
                        required={true}
                        options={["ไม่เคย", "เคย"]}
                        name="ท่านเคยผ่าตัดบริเวณใบหน้า หู คอ จมูก"
                        isnote={true}
                        note={demographicDoc.info.pastSurgeriesNote}
                        refNote={"pastSurgeriesNote"}
                        checked={demographicDoc.info.pastSurgeries}
                        refValue={"pastSurgeries"}
                      ></InputRadio>
                      <InputRadio
                        title="ท่านมียาที่รับประทานเป็นประจำหรือไม่"
                        required={true}
                        options={["ไม่มี", "มี"]}
                        name="ท่านมียาที่รับประทานเป็นประจำหรือไม่"
                        isnote={true}
                        note={demographicDoc.info.currentMedicationsNote}
                        refNote={"currentMedicationsNote"}
                        checked={demographicDoc.info.currentMedications}
                        refValue={"currentMedications"}
                      ></InputRadio>
                      <InputRadio
                        title="ท่านใช้ยานอนหลับ"
                        required={true}
                        options={["ไม่เคยใช้", "ทุกคืน", "ใช้"]}
                        name="ท่านใช้ยานอนหลับ"
                        isnote={true}
                        note={demographicDoc.info.sleepingPillsFrequency}
                        refNote={"sleepingPillsFrequency"}
                        checked={demographicDoc.info.sleepingPills}
                        refValue={"sleepingPills"}
                      ></InputRadio>
                      <InputRadio
                        title="ท่านสูบบุหรี่หรือไม่"
                        required={true}
                        options={[
                          "ไม่เคย",
                          "เคยแต่เลิกแล้ว",
                          "ยังสูบอยู่",
                          "เฉพาะเข้าสังคม",
                        ]}
                        name="ท่านสูบบุหรี่หรือไม่"
                        checked={demographicDoc.info.smokingStatus}
                        refValue={"smokingStatus"}
                      ></InputRadio>
                      <InputRadio
                        title="ท่านดื่มเครื่องดื่มที่มีแอลกอฮอล์"
                        required={true}
                        options={[
                          "ไม่เคย",
                          "เคยแต่เลิกแล้ว",
                          "ยังดื่มอยู่",
                          "เฉพาะเข้าสังคม",
                        ]}
                        name="ท่านดื่มเครื่องดื่มที่มีแอลกอฮอล์"
                        checked={demographicDoc.info.alcoholConsumption}
                        refValue={"alcoholConsumption"}
                      ></InputRadio>
                      <InputRadio
                        title="ท่านดื่มเครื่องดื่มที่มีคาเฟอีน เช่น ชา กาแฟ น้ำอัดลม"
                        required={true}
                        options={[
                          "ไม่ดื่ม",
                          "1-3 แก้ว/วัน",
                          "มากกว่า 3 แก้ว/วัน",
                        ]}
                        name="ท่านดื่มเครื่องดื่มที่มีคาเฟอีน เช่น ชา กาแฟ น้ำอัดลม"
                        checked={demographicDoc.info.caffeineConsumption}
                        refValue={"caffeineConsumption"}
                      ></InputRadio>
                      <InputRadio
                        title="ความเปลี่ยนแปลงน้ำหนักตัวใน 1 ปีที่ผ่านมา"
                        required={true}
                        options={["คงที่", "เพิ่มขึ้น", "ลดลง"]}
                        name="ความเปลี่ยนแปลงน้ำหนักตัวใน 1 ปีที่ผ่านมา"
                        checked={demographicDoc.info.weightChangeLastYear}
                        refValue={"weightChangeLastYear"}
                      ></InputRadio>
                      <InputBox
                        title="น้ำหนักที่เปลี่ยนแปลง"
                        measure=""
                        refValue={(el) =>
                          (demographicRef.current["weightChangeNote"] = el)
                        }
                        defaultValue={demographicDoc.info.weightChangeNote}
                      ></InputBox>
                      <InputRadio
                        title="ท่านออกกำลังกาย"
                        required={true}
                        options={["ไม่เคยเลย", "นานๆครั้ง", "บ่อยๆทุกวัน"]}
                        name="ท่านออกกำลังกาย"
                        checked={demographicDoc.info.exerciseStatus}
                        refValue={"exerciseStatus"}
                      ></InputRadio>
                      <InputRadio
                        title="ท่านมีญาติพี่น้องมีประวัติโรคการนอนผิดปกติ"
                        required={true}
                        options={["ไม่มี", "มี"]}
                        name="ท่านมีญาติพี่น้องมีประวัติโรคการนอนผิดปกติ"
                        isnote={true}
                        note={demographicDoc.info.familySleepDisordersNote}
                        refNote={"familySleepDisordersNote"}
                        checked={demographicDoc.info.familySleepDisorders}
                        refValue={"familySleepDisorders"}
                      ></InputRadio>
                      <h4 className="mt-5 mb-4">
                        <b>ท่านอนที่ท่านรู้สึกสบายที่สุด</b>
                      </h4>{" "}
                      <InputRadio
                        title="ท่านอน"
                        required={true}
                        options={[
                          "นอนหงาย",
                          "นอนตะแคง",
                          "นอนคว่ำ",
                          "นั่งหลับ",
                          "ไม่แตกต่างกัน",
                        ]}
                        name="ท่านอน"
                        checked={demographicDoc.info.sleepingPosition}
                        refValue={"sleepingPosition"}
                      ></InputRadio>
                      <h4 className="mt-5 mb-4">
                        <b>สาเหตุที่มารับการรักษา</b>
                      </h4>{" "}
                      <InputRadio
                        title="อาการสำคัญ"
                        required={true}
                        options={[
                          "กรน",
                          "ง่วง/หลับ ตอนกลางวัน",
                          "หยุดหายใจขณะหลับ",
                          "กัดฟัน",
                          "นอนไม่หลับ",
                          "สะดุ้งตื่นบ่อยตอนกลางคืน",
                          "นอนขากระตุก",
                          "ฝันร้าย",
                          "ตื่นมาคอกแห้งปากแห้ง",
                          "ตื่นตอนเช้าไม่สดชื่น",
                          "ปัสสาวะบ่อยตอนกลางคืน",
                          "นอนละเมอ",
                          "สำลักน้ำลาย",
                          "อื่นๆ(ระบุ)",
                        ]}
                        name="อาการสำคัญ"
                        checked={demographicDoc.info.symptoms}
                        refValue={"symptoms"}
                      ></InputRadio>
                      <InputBox
                        title=""
                        measure=""
                        refValue={(el) =>
                          (demographicRef.current["symptomsNote"] = el)
                        }
                        defaultValue={demographicDoc.info.symptomsNote}
                      ></InputBox>
                      <InputBox
                        title="มีอาการเหล่านี้มานาน"
                        measure="เดือน/ปี"
                        required={true}
                        refValue={(el) =>
                          (demographicRef.current["symptomsDuration"] = el)
                        }
                        defaultValue={demographicDoc.info.symptomsDuration}
                      ></InputBox>
                      <h4 className="mt-5 mb-4">
                        <b>Epworth Sleepiness Scala</b>
                      </h4>{" "}
                      <h6 className="mt-5 mb-4">
                        <p>
                          มีความเป็นไปได้แค่ไหนที่คุณจะง่วงจนงีบหรือเผลอหลับในสถานการณ์ต่างๆ
                          ดังต่อไปนี้ 0 = ไม่เคยเลย 1 = มีโอกาสเล็กน้อย
                          หรือนานๆครั้ง 2 = มีโอกาสปานกลาง 3 = มีโอกาสสูง
                          หรือประจำ
                        </p>
                      </h6>{" "}
                      <InputRadio
                        title="1. ขณะนั่งอ่านหนังสือ"
                        options={["0", "1", "2", "3"]}
                        name="ขณะนั่งอ่านหนังสือ"
                        checked={demographicDoc.info.readingSleepiness}
                        refValue={"readingSleepiness"}
                      ></InputRadio>
                      <InputRadio
                        title="2. ขณะดูโทรทัศน์"
                        options={["0", "1", "2", "3"]}
                        name="ขณะดูโทรทัศน์"
                        checked={demographicDoc.info.tvWatchingSleepiness}
                        refValue={"tvWatchingSleepiness"}
                      ></InputRadio>
                      <InputRadio
                        title="3. ขณะนั่งเฉยๆ นอกบ้าน ในที่สาธารณะ เช่น ห้องสมุด หรือโรงภาพยนตร์"
                        options={["0", "1", "2", "3"]}
                        name="ขณะนั่งเฉยๆ นอกบ้าน ในที่สาธารณะ เช่น ห้องสมุด หรือโรงภาพยนตร์"
                        checked={demographicDoc.info.publicPlaceSleepiness}
                        refValue={"publicPlaceSleepiness"}
                      ></InputRadio>
                      <InputRadio
                        title="4. ขณะเป็นผู้โดยสารในรถ เรือ รถไฟ เครื่องบิน ติดต่อกันเป็นเวลานาน"
                        options={["0", "1", "2", "3"]}
                        name="ขณะเป็นผู้โดยสารในรถ เรือ รถไฟ เครื่องบิน ติดต่อกันเป็นเวลานาน"
                        checked={
                          demographicDoc.info.longTripPassengerSleepiness
                        }
                        refValue={"longTripPassengerSleepiness"}
                      ></InputRadio>
                      <InputRadio
                        title="5. ขณะนั่งเงียบๆ หลังรับประทานอาหารกลางวัน โดยไม่ได้ดื่มแอลกอฮอล์"
                        options={["0", "1", "2", "3"]}
                        name="ขณะนั่งเงียบๆ หลังรับประทานอาหารกลางวัน โดยไม่ได้ดื่มแอลกอฮอล์"
                        checked={demographicDoc.info.afterLunchSleepiness}
                        refValue={"afterLunchSleepiness"}
                      ></InputRadio>
                      <InputRadio
                        title="6. ขณะนั่งและพูดคุยกับผู้อื่น"
                        options={["0", "1", "2", "3"]}
                        name="ขณะนั่งและพูดคุยกับผู้อื่น"
                        checked={demographicDoc.info.conversationSleepiness}
                        refValue={"conversationSleepiness"}
                      ></InputRadio>
                      <InputRadio
                        title="7. ขณะนั่งเอนหลังพักผ่อนช่วงบ่ายตามโอกาส"
                        options={["0", "1", "2", "3"]}
                        name="ขณะนั่งเอนหลังพักผ่อนช่วงบ่ายตามโอกาส"
                        checked={demographicDoc.info.afternoonRestSleepiness}
                        refValue={"afternoonRestSleepiness"}
                      ></InputRadio>
                      <InputRadio
                        title="8. ขณะขับรถแล้วรถต้องหยุดนิ่ง 2-3 นาที ตามจังหวะการจราจร"
                        options={["0", "1", "2", "3"]}
                        name="ขณะขับรถแล้วรถต้องหยุดนิ่ง 2-3 นาที ตามจังหวะการจราจร"
                        checked={demographicDoc.info.trafficStopSleepiness}
                        refValue={"trafficStopSleepiness"}
                      ></InputRadio>
                      <h4 className="mt-5 mb-4">
                        <b>ข้อมูลเกี่ยวกับตารางการนอนหลับ</b>
                      </h4>{" "}
                      <h6 className="mt-5 mb-4">
                        <p>วันธรรมดา</p>
                      </h6>{" "}
                      <InputBox
                        title="ท่านเข้านอนเวลา"
                        measure=""
                        required={true}
                        refValue={(el) =>
                          (demographicRef.current["weekdayBedtime"] = el)
                        }
                        defaultValue={demographicDoc.info.weekdayBedtime}
                      ></InputBox>
                      <InputBox
                        title="ท่านตื่นนอนเวลา"
                        measure=""
                        required={true}
                        refValue={(el) =>
                          (demographicRef.current["weekdayWakeupTime"] = el)
                        }
                        defaultValue={demographicDoc.info.weekdayWakeupTime}
                      ></InputBox>
                      <InputBox
                        title="หลังจากปิดไฟเข้านอนเมื่อหัวถึงหมอน ท่านใช้เวลานาน"
                        measure="นาที จึงหลับ"
                        required={true}
                        refValue={(el) =>
                          (demographicRef.current["weekdayFallAsleepTime"] = el)
                        }
                        defaultValue={demographicDoc.info.weekdayFallAsleepTime}
                      ></InputBox>
                      <InputBox
                        title="จำนวนชั่วโมงที่ท่านคิดว่า หลับจริงโดยเฉลี่ยในแต่ละคืน"
                        measure="ชั่วโมง"
                        required={true}
                        refValue={(el) =>
                          (demographicRef.current["weekdayAverageSleepHours"] =
                            el)
                        }
                        defaultValue={
                          demographicDoc.info.weekdayAverageSleepHours
                        }
                      ></InputBox>
                      <InputRadio
                        title="ท่านตื่นขึ้นมาตอนกลางคืนหลังจากหลับไปแล้วหรือไม่ (วันธรรมดา)"
                        required={true}
                        options={["ตื่น", "ไ่ม่ตื่น"]}
                        name="ท่านตื่นขึ้นมาตอนกลางคืนหลังจากหลับไปแล้วหรือไม่ (วันธรรมดา)"
                        checked={demographicDoc.info.weekdayNightAwakenings}
                        refValue={"weekdayNightAwakenings"}
                      ></InputRadio>
                      <InputBox
                        title="ถ้าตื่น ท่านตื่นกี่ครั้ง"
                        measure="ครั้ง"
                        refValue={(el) =>
                          (demographicRef.current[
                            "weekdayNightAwakeningsCount"
                          ] = el)
                        }
                        defaultValue={
                          demographicDoc.info.weekdayNightAwakeningsCount
                        }
                      ></InputBox>
                      <InputBox
                        title="เป็นเวลารวมทั้งสิ้น"
                        measure="ชั่วโมง"
                        refValue={(el) =>
                          (demographicRef.current["weekdayNightAwakeDuration"] =
                            el)
                        }
                        defaultValue={
                          demographicDoc.info.weekdayNightAwakeDuration
                        }
                      ></InputBox>
                      <h6 className="mt-5 mb-4">
                        <p>วันหยุด</p>
                      </h6>{" "}
                      <InputBox
                        title="ท่านเข้านอนเวลา"
                        measure=""
                        required={true}
                        refValue={(el) =>
                          (demographicRef.current["weekendBedtime"] = el)
                        }
                        defaultValue={demographicDoc.info.weekendBedtime}
                      ></InputBox>
                      <InputBox
                        title="ท่านตื่นนอนเวลา"
                        measure=""
                        required={true}
                        refValue={(el) =>
                          (demographicRef.current["weekendWakeupTime"] = el)
                        }
                        defaultValue={demographicDoc.info.weekendWakeupTime}
                      ></InputBox>
                      <InputBox
                        title="หลังจากปิดไฟเข้านอนเมื่อหัวถึงหมอน ท่านใช้เวลานาน"
                        measure="นาที จึงหลับ"
                        required={true}
                        refValue={(el) =>
                          (demographicRef.current["weekendFallAsleepTime"] = el)
                        }
                        defaultValue={demographicDoc.info.weekendFallAsleepTime}
                      ></InputBox>
                      <InputBox
                        title="จำนวนชั่วโมงที่ท่านคิดว่า หลับจริงโดยเฉลี่ยในแต่ละคืน"
                        measure="ชั่วโมง"
                        required={true}
                        refValue={(el) =>
                          (demographicRef.current["weekendAverageSleepHours"] =
                            el)
                        }
                        defaultValue={
                          demographicDoc.info.weekendAverageSleepHours
                        }
                      ></InputBox>
                      <InputRadio
                        title="ท่านตื่นขึ้นมาตอนกลางคืนหลังจากหลับไปแล้วหรือไม่ (วันหยุด)"
                        required={true}
                        options={["ตื่น", "ไ่ม่ตื่น"]}
                        name="ท่านตื่นขึ้นมาตอนกลางคืนหลังจากหลับไปแล้วหรือไม่ (วันหยุด)"
                        checked={demographicDoc.info.weekendNightAwakenings}
                        refValue={"weekendNightAwakenings"}
                      ></InputRadio>
                      <InputBox
                        title="ถ้าตื่น ท่านตื่นกี่ครั้ง"
                        measure="ครั้ง"
                        refValue={(el) =>
                          (demographicRef.current[
                            "weekendNightAwakeningsCount"
                          ] = el)
                        }
                        defaultValue={
                          demographicDoc.info.weekendNightAwakeningsCount
                        }
                      ></InputBox>
                      <InputBox
                        title="เป็นเวลารวมทั้งสิ้น"
                        measure="ชั่วโมง"
                        refValue={(el) =>
                          (demographicRef.current["weekendNightAwakeDuration"] =
                            el)
                        }
                        defaultValue={
                          demographicDoc.info.weekendNightAwakeDuration
                        }
                      ></InputBox>
                      <InputRadio
                        title="ปกติท่านนอนงีบหลังจากตื่นตอนเช้าหรือไม่"
                        required={true}
                        options={["งีบ", "ไม่งีบ"]}
                        name="ปกติท่านนอนงีบหลังจากตื่นตอนเช้าหรือไม่"
                        checked={demographicDoc.info.napStatus}
                        refValue={"napStatus"}
                      ></InputRadio>
                      <InputBox
                        title="ถ้างีบ ท่านงีบสัปดาห์ละ"
                        measure="ครั้ง"
                        refValue={(el) =>
                          (demographicRef.current["napCount"] = el)
                        }
                        defaultValue={demographicDoc.info.napCount}
                      ></InputBox>
                      <InputBox
                        title="ถ้างีบ เวลาใดบ้างโปรดระบุ"
                        measure=""
                        refValue={(el) =>
                          (demographicRef.current["napTime"] = el)
                        }
                        defaultValue={demographicDoc.info.napTime}
                      ></InputBox>
                      <InputRadio
                        title="ท่านทำงานเป็นกะหรือไม่"
                        options={["ทำงานเป็นกะ", "ไม่ทำงานเป็นกะ"]}
                        name="ท่านทำงานเป็นกะหรือไม่"
                        checked={demographicDoc.info.worksShifts}
                        refValue={"worksShifts"}
                      ></InputRadio>
                      <p>ถ้าทำงานเป็นกะ</p>
                      <InputBox
                        title="เริ่ม"
                        measure="นาฬิกา"
                        refValue={(el) =>
                          (demographicRef.current["shiftStartTime"] = el)
                        }
                        defaultValue={demographicDoc.info.shiftStartTime}
                      ></InputBox>
                      <InputBox
                        title="ถึง"
                        measure="นาฬิกา"
                        refValue={(el) =>
                          (demographicRef.current["shiftEndTime"] = el)
                        }
                        defaultValue={demographicDoc.info.shiftEndTime}
                      ></InputBox>
                      <InputBox
                        title="ทำงานเป็นกะ"
                        measure="วันต่อสัปดาห์"
                        refValue={(el) =>
                          (demographicRef.current["shiftDaysPerWeek"] = el)
                        }
                        defaultValue={demographicDoc.info.shiftDaysPerWeek}
                      ></InputBox>
                      <InputBox
                        title="โปรดระบุวัน"
                        measure=""
                        refValue={(el) =>
                          (demographicRef.current["shiftDays"] = el)
                        }
                        defaultValue={demographicDoc.info.shiftDays}
                      ></InputBox>
                      <InputBox
                        title="วันที่ทำงานเป็นกะ เข้านอนเวลา"
                        measure="นาฬิกา"
                        refValue={(el) =>
                          (demographicRef.current["shiftDayBedtime"] = el)
                        }
                        defaultValue={demographicDoc.info.shiftDayBedtime}
                      ></InputBox>
                      <InputBox
                        title="วันที่ทำงานเป็นกะ ตื่นนอนเวลา"
                        measure="นาฬิกา"
                        refValue={(el) =>
                          (demographicRef.current["shiftDayWakeupTime"] = el)
                        }
                        defaultValue={demographicDoc.info.shiftDayWakeupTime}
                      ></InputBox>
                      <InputBox
                        title="วันที่ไม่ได้ทำงานเป็นกะ เข้านอนเวลา"
                        measure="นาฬิกา"
                        refValue={(el) =>
                          (demographicRef.current["nonShiftDayBedtime"] = el)
                        }
                        defaultValue={demographicDoc.info.nonShiftDayBedtime}
                      ></InputBox>
                      <InputBox
                        title="วันที่ไม่ได้ทำงานเป็นกะ ตื่นนอนเวลา"
                        measure="นาฬิกา"
                        refValue={(el) =>
                          (demographicRef.current["nonShiftDayWakeupTime"] = el)
                        }
                        defaultValue={demographicDoc.info.nonShiftDayWakeupTime}
                      ></InputBox>
                      <h4 className="mt-5 mb-4">
                        <b>การวิเคราะห์อาการ</b>
                      </h4>{" "}
                      <h6 className="mt-5 mb-4">
                        <b>ประเมินความถี่ในการเกิดสถานการณ์ต่างๆ</b>
                      </h6>{" "}
                      <h6 className="mt-5 mb-4">
                        <p>
                          โดยให้คะแนนเป็น 0 = ไม่เคยเลย, 1= 1-2 ครั้ง/สัปดาห์,
                          2= 3-4 ครั้ง/สัปดาห์, 3= เกือบทุกวัน
                        </p>
                      </h6>{" "}
                      <InputRadio
                        title="1. ท่านไม่สามารถหลับได้ภายในเวลาครึ่งชั่วโมง"
                        options={["0", "1", "2", "3"]}
                        name="ท่านไม่สามารถหลับได้ภายในเวลาครึ่งชั่วโมง"
                        checked={demographicDoc.info.difficultyFallingAsleep}
                        refValue={"difficultyFallingAsleep"}
                      ></InputRadio>
                      <InputRadio
                        title="2. ท่านเป็นตะคริวที่ขาในเวลาเข้านอน"
                        options={["0", "1", "2", "3"]}
                        name="ท่านเป็นตะคริวที่ขาในเวลาเข้านอน"
                        checked={demographicDoc.info.legCrampsAtNight}
                        refValue={"legCrampsAtNight"}
                      ></InputRadio>
                      <InputRadio
                        title="3. ท่านตื่นขึ้นมากลางดึก หรือ ตื่นเร็วกว่าที่ตั้งใจ"
                        options={["0", "1", "2", "3"]}
                        name="ท่านตื่นขึ้นมากลางดึก หรือ ตื่นเร็วกว่าที่ตั้งใจ"
                        checked={demographicDoc.info.wakesUpAtNight}
                        refValue={"wakesUpAtNight"}
                      ></InputRadio>
                      <p>
                        หากท่านตื่นขึ้นมากลางดึกเป็นประจำ กรุณาตอบคำถามต่อไปนี้
                      </p>
                      <InputBox
                        title="3.1 ท่านตื่นขึ้นมากลางดึกเนื่องจาก"
                        measure=""
                        refValue={(el) =>
                          (demographicRef.current["nightWakeReason"] = el)
                        }
                        defaultValue={demographicDoc.info.nightWakeReason}
                      ></InputBox>
                      <InputBox
                        title="3.2 ท่านตื่นขึ้นมากลางดึกประมาณ"
                        measure="ครั้งต่อคืน"
                        refValue={(el) =>
                          (demographicRef.current["nightWakeFrequency"] = el)
                        }
                        defaultValue={demographicDoc.info.nightWakeFrequency}
                      ></InputBox>
                      <InputBox
                        title="3.3 เวลาที่ท่านมักตื่นขึ้นมากลางดึก"
                        measure=""
                        refValue={(el) =>
                          (demographicRef.current["nightWakeTime"] = el)
                        }
                        defaultValue={demographicDoc.info.nightWakeTime}
                      ></InputBox>
                      <InputRadio
                        title="3.4 หลังจากที่ตื่นกลางดึกท่านสามารถหลับอีกครั้งได้"
                        options={["ง่าย", "ยาก", "ไม่สามารถหลับได้อีก"]}
                        name="หลังจากที่ตื่นกลางดึกท่านสามารถหลับอีกครั้งได้"
                        checked={demographicDoc.info.difficultyReturningToSleep}
                        refValue={"difficultyReturningToSleep"}
                      ></InputRadio>
                      <InputRadio
                        title="4. ท่านมีเหงื่อออกมาระหว่างการนอน"
                        required={true}
                        options={["0", "1", "2", "3"]}
                        name="ท่านมีเหงื่อออกมาระหว่างการนอน"
                        checked={demographicDoc.info.nightSweats}
                        refValue={"nightSweats"}
                      ></InputRadio>
                      <InputRadio
                        title="5. ท่านเป็นตะคริวหรือรู้สึกว่ามีแมลงไต่ขาขณะเข้านอน"
                        required={true}
                        options={["0", "1", "2", "3"]}
                        name="ท่านเป็นตะคริวหรือรู้สึกว่ามีแมลงไต่ขาขณะเข้านอน"
                        checked={
                          demographicDoc.info.legCrampsOrCrawlingSensation
                        }
                        refValue={"legCrampsOrCrawlingSensation"}
                      ></InputRadio>
                      <InputRadio
                        title="6. ท่านไม่เคยแยกเหตุการ์ณว่าจริงหรือฝัน"
                        required={true}
                        options={["0", "1", "2", "3"]}
                        name="ท่านไม่เคยแยกเหตุการ์ณว่าจริงหรือฝัน"
                        checked={
                          demographicDoc.info.confusionBetweenRealityAndDream
                        }
                        refValue={"confusionBetweenRealityAndDream"}
                      ></InputRadio>
                      <InputRadio
                        title="7. ท่านมีอาการนอนกรน"
                        required={true}
                        options={["0", "1", "2", "3"]}
                        name="ท่านมีอาการนอนกรน"
                        checked={demographicDoc.info.snoring}
                        refValue={"snoring"}
                      ></InputRadio>
                      <InputRadio
                        title="ความดังของการนอนกรนของท่าน"
                        options={[
                          "ดังกว่าเสียงหายใจเล็กน้อย",
                          "ดังเท่าเสียงฮัมเพลง",
                          "ดังกว่าเสียงพูดคุย",
                          "ได้ยินผ่านประตูที่ปิดอยู่",
                        ]}
                        name="ความดังของการนอนกรนของท่าน"
                        checked={demographicDoc.info.snoringLoudness}
                        refValue={"snoringLoudness"}
                      ></InputRadio>
                      <InputRadio
                        title="7.1 หยุดหายใจเป็นช่วงๆ"
                        required={true}
                        options={["0", "1", "2", "3"]}
                        name="หยุดหายใจเป็นช่วงๆ"
                        checked={demographicDoc.info.breathingPausesDuringSleep}
                        refValue={"breathingPausesDuringSleep"}
                      ></InputRadio>
                      <InputRadio
                        title="7.2 มีอาการเหมือนสำลักน้ำ"
                        required={true}
                        options={["0", "1", "2", "3"]}
                        name="มีอาการเหมือนสำลักน้ำ"
                        checked={
                          demographicDoc.info.chokingSensationDuringSleep
                        }
                        refValue={"chokingSensationDuringSleep"}
                      ></InputRadio>
                      <InputRadio
                        title="7.3 มีการกำมือแน่นเป็นช่วงๆ"
                        required={true}
                        options={["0", "1", "2", "3"]}
                        name="มีการกำมือแน่นเป็นช่วงๆ"
                        checked={demographicDoc.info.fistClenchingDuringSleep}
                        refValue={"fistClenchingDuringSleep"}
                      ></InputRadio>
                      <InputRadio
                        title="7.4 มีการกระตุกของแขนหรือขา"
                        required={true}
                        options={["0", "1", "2", "3"]}
                        name="มีการกระตุกของแขนหรือขา"
                        checked={demographicDoc.info.limbJerkingDuringSleep}
                        refValue={"limbJerkingDuringSleep"}
                      ></InputRadio>
                      <InputRadio
                        title="7.5 มีการกระสับกระส่ายเหงื่อออกมาก"
                        required={true}
                        options={["0", "1", "2", "3"]}
                        name="มีการกระสับกระส่ายเหงื่อออกมาก"
                        checked={
                          demographicDoc.info.restlessnessAndSweatingDuringSleep
                        }
                        refValue={"restlessnessAndSweatingDuringSleep"}
                      ></InputRadio>
                      <InputRadio
                        title="7.6 มีอาการละเมอ"
                        required={true}
                        options={["0", "1", "2", "3"]}
                        name="มีอาการละเมอ"
                        checked={demographicDoc.info.sleepwalkingOrTalking}
                        refValue={"sleepwalkingOrTalking"}
                      ></InputRadio>
                      <InputRadio
                        title="8. หลังตื่นนอนตอนเช้า ท่านรู้สึก"
                        required={true}
                        options={["สดชื่นแจ่มใส", "อ่อนเพลีย", "ปวดศีรษะ"]}
                        name="หลังตื่นนอนตอนเช้า ท่านรู้สึก"
                        checked={demographicDoc.info.feelingAfterWakingUp}
                        refValue={"feelingAfterWakingUp"}
                      ></InputRadio>
                      <InputRadio
                        title="9. โดยรวมแล้ว ท่านคิดว่า การนอนของท่าน"
                        required={true}
                        options={["ดีมาก", "ดี", "แย่", "แย่มาก"]}
                        name="โดยรวมแล้ว ท่านคิดว่า การนอนของท่าน"
                        checked={demographicDoc.info.overallSleepQuality}
                        refValue={"overallSleepQuality"}
                      ></InputRadio>
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-sm-10">
                      <button type="submit" className="btn btn-primary">
                        บันทึกข้อมูล
                      </button>
                      <button
                        onClick={() => {
                          history.push(`/patient-page/${id}`);
                        }}
                        type="submit"
                        className="btn btn-light ml-2"
                      >
                        ยกเลิก
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            ) : (
              <></>
            )}
          </Card.Body>
        </Card>
      </Col>
    </Fragment>
  );
};

export default Demographic;
